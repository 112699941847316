<template>
  <div class="d-flex justify-content-between align-items-start p-2"> 

    <div class="absolute-left-text">
      <span class="my-0 mx-1 text-secondary">hold <b> Shift</b> to multi-select Select</span>
    </div> 

    <div class="mt-50 d-flex">
      
      <b-collapse :visible="getSelectedMiddlewares.data.length > 0">
          <b-button class="mx-25 py-25 px-75 position-relative" variant="outline-light" @click="clearSelection()">
            <b-icon icon="x"/>
            Clear Selection
            <span class="above-btn-hint">Esc</span>
          </b-button>
      </b-collapse>

      <b-button class="mx-25 py-25 px-75 position-relative" variant="outline-light" @click="selectAllMids()">
        Select All
        <span class="above-btn-hint">Ctrl + A</span>
      </b-button>

      <b-collapse :visible="getSelectedMiddlewares.data.length > 0">
        <b-button class="mx-25 py-25 px-75 position-relative" variant="outline-light" @click="initReorderingMiddlewares()">
          <b-icon icon="arrow-down-up" scale="0.9"/>
          <span class="above-btn-hint"> Ctrl + X</span>
          Reorder
        </b-button>

        <b-button class="mx-25 py-25 px-75 position-relative" variant="outline-light" @click="copy()">
          <b-icon icon="clipboard-check" />
          Copy 
          <span class="above-btn-hint"> Ctrl + C</span>
        </b-button>

        <b-button class="ml-3 py-25 px-75 position-relative"
          variant="outline-danger" @click="openDeleteModal()">
          <b-icon icon="trash"/>
          <span class="above-btn-hint">Del</span>
        </b-button>
      </b-collapse>
    </div>

    <div class="mt-50">

      <!-- Flux Clipboard ================================== -->
        <flux-clipboard :ref="'flux-clipboard'" @paste-to-flux="((params)=>{ $emit('paste-to-flux', params)})"/>
      <!-- END Flux Clipboard ============================== -->

    </div>

    <div class="overlay-zoom-controller">
      <div align="center" class="title">
        <b-icon icon="plus" class="float-right mx-1" scale="1.35" rotate="90"/>
        <b-icon icon="dash" class="float-left mx-1" scale="1.35" rotate="90"/>
        <h5 class="text-secondary font-weight-bolder">ZOOM</h5>
      </div>
      <b-form-input type="range" v-model="zoomOutScale" min="0.2" max="9" step="0.2" @input="$emit('zoomChanged', zoomOutScale)"/>
    </div>

    <b-modal 
      ref="bulk-deletion-modal"
      centered
      ok-variant="danger"
      size="md"
      auto-focus-button="ok"
      @ok="deleteSelectedMiddlewares"
      @hide="((e)=>{if (loadingActive) e.preventDefault()})"
    >
      <template #modal-title>
        <div>
          Deleting multiple Middlewares
        </div>
      </template>
      <template v-if="getSelectedMiddlewares.data && getSelectedMiddlewares.data.length > 0">
        <p>
          Are you sure you want to delete the selected Middlewares?
        </p>
        <ul class="bg-dark-blue rounded py-50 pr-1 pl-2 small m-0">
          <li v-for="mid in getSelectedMiddlewares.data" :key="'deleting-mid-'+mid.id" class="mb-25">
            <span >{{$t(mid.enum_type.label)}} - <b class="text-light">{{mid.name}} </b> </span>
          </li>
        </ul>
        <div class="small text-secondary px-50">Any Middlewares inside these will also be deleted</div>
      </template>

      <template #modal-ok>
        <div>
          <span v-if="!loadingActive">Confirm</span>
          <b-spinner small v-else/>
        </div>
      </template>
    </b-modal>
<!-- {{ getSelectedMiddlewares.data.length }} -->
  </div> 
</template>

<script>
import { mapGetters } from 'vuex';

import {
  BButton,
  BCard,
  BTabs,
  BTab,
  BFormInput,
  BCollapse,
  BSpinner,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BFormRadio,
} from 'bootstrap-vue'
import fluxClipboard from '@/views/pages/middleware/MiddlewareManagement/RearrangementOverlay/FluxClipboard.vue'
import { makeToast } from '@/layouts/components/Popups';
import middlewares from '@/custom/class/Enum/Middlewares.js'
import anime from 'animejs';

  export default {
    components: {
      BButton,
      BCard,
      BTabs,
      BTab,
      BFormInput,
      fluxClipboard,
      BCollapse,
      BSpinner,
      BInputGroup,
      BInputGroupAppend,
      BFormCheckbox,
      BFormRadio,
    },
    props: {
      initialZoom: {
        type: Number,
        default: 9
      },
    },
    data() {
      return {
        zoomOutScale: this.initialZoom,
        show_options: false,
        loadingActive: false,
      }
    },
    computed: {
      ...mapGetters(["getSelectedMiddlewares"]),
    },
    methods: {
      clearSelection(){
        this.$store.dispatch('clearMiddlewareSelection')
      },
      initReorderingMiddlewares(){
        if (this.getSelectedMiddlewares.selectedIDs.length){
          this.$store.dispatch('userMarkSelectedMiddlewaresAsReorder')
          this.$nextTick(()=>{
            this.$emit('initReorder',this.getSelectedMiddlewares)
          })
        }
      },
      deleteSelectedMiddlewares(e){
        e.preventDefault();
        
        this.loadingActive = true;

        let removedIDs = []
        let midsPayload = ""
        let midEnum = new middlewares().items

        this.getSelectedMiddlewares.data.forEach(el => {
          removedIDs.push(el.id)

          if (! (midEnum.find(e => e.id == el.enum_type.id).is_end_block)){
            midsPayload = `${midsPayload}${el.id},`
          }
        });

        midsPayload = midsPayload.slice(0,-1)
        this.$store.dispatch('bulkDeleteMiddlewares',{middlewares: midsPayload, transmissionID:this.$route.params.transmissionID})
        .then((resp)=>{
          this.loadingActive = false
          makeToast({
            title: 'Success!',
            text: 'Middlewares successfully deleted',
            variant: "success",
            icon: "CheckIcon",
          })
          this.$nextTick(()=>{
            this.$refs['bulk-deletion-modal'].hide()
          })
          this.$emit('removed', removedIDs)
        })
        .catch((err)=>{
          console.error(err)
        })
        .finally(()=>{
          this.loadingActive = false
          this.$store.dispatch('clearMiddlewareSelection')
          this.$emit('toggleOverlay', false)
        })
      },
      copy(){
        if (this.getSelectedMiddlewares.data && this.getSelectedMiddlewares.data.length > 0){
          this.$refs['flux-clipboard'].putInClipboard(this.getSelectedMiddlewares.data)
          this.clearSelection()
        }
      },
      paste(){
        this.$refs['flux-clipboard'].paste()
      },
      openDeleteModal(){
        if (this.getSelectedMiddlewares.data.length > 0){
          this.$refs['bulk-deletion-modal'].show()
        }
      },
      forceImport(compressed){
        this.$refs['flux-clipboard'].forceImport(compressed)
      },
      selectAllMids(){
        this.$emit('select-all-middlewares')
      },
    },
  }
</script>

<style lang="scss" scoped>
.absolute-left-text{
  position: absolute;
  right: 10px;
  top: 0;
}
.above-btn-hint{
  position: absolute;
  left: 5px;
  top: -13px;
  font-size: 10px;
  color: var(--secondary) !important;
}

.overlay-zoom-controller{
  width: 200px;
  transform: rotate(-90deg);
  background-color: none;
  position: absolute;
  left:-55px;
  bottom: 110px;
  input, input:active, input:focus{
    background-color: transparent;
    filter: grayscale(1) brightness(0.7) contrast(1.5);
  }
  .title{
    letter-spacing: 6px;
  }
}
</style>