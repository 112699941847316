<template>
  <div class="category-header p-2 mb-2 shadow">

    <create-service-modal ref="create-service-modal" @serviceCreated="serviceCreated"/>
    <create-event-modal ref="create-event-modal" @eventCreated="eventCreated"/>

    <b-row cols="10" class="middleware-flux-header-select-category">
      <b-col cols="10" md="4" class="pr-2">
        <div class="d-flex justify-content-between">
            <div class="small text-muted text-uppercase font-weight-bolder">
              {{ $t("pages.middleware_categories.selected_service") }}
            </div>
            <div class="small text-muted">
              <b-button class="m-0 p-0 small invisible" variant="none" @click="$refs['create-service-modal'].show()" v-if="!userCantEdit">
                <span class="border-bottom-favorite">
                  <b-icon icon="plus" scale="1.2" class="" shift-v="-1" variant="favorite"/>
                  <span class="small font-weight-bolder">
                    Add Service
                  </span>
                </span>
              </b-button>
              
            </div>
        </div>
        <div class="mt-1">
          <v-select
            v-if="serviceList"  
            v-model="category.service_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="identifier"
            :options="serviceList"
            placeholder="Select the service"
            appendToBody
            :reduce="(option) => { return parseInt(option.id) }"
            @input="$emit('serviceChanged'); decideCurrentCategory()"
          >
            <template #no-options>{{ $t("pages.middleware_categories.coudnt_find_services") }}</template>

          </v-select>
          <div v-else>
            <b-skeleton type="input"></b-skeleton>
          </div>
        </div>
        
      </b-col>
      <b-col cols="10" md="4" class="pr-2"> 
        <div class="d-flex justify-content-between">
          <div class="small text-muted text-uppercase font-weight-bolder">
            {{ $t("pages.middleware_categories.selected_event") }}
          </div>
          <div class="small">
            <b-button class="m-0 p-0  small" variant="none" @click="$refs['create-event-modal'].show()" v-if="!userCantEdit">
              <span class="border-bottom-favorite">
                <b-icon icon="plus" scale="1" class="" shift-v="-1" variant="favorite"/> 
                <span class="small font-weight-bolder">
                  Add Event
                </span>
              </span>
            </b-button>
          </div>
        </div>
        <div class="mt-1">
          <v-select
            v-if="eventList"
            appendToBody
            v-model="category.event_id"
            :options="eventList"
            placeholder="Select the event"

            label="event_identifier"

            :reduce="(option) => { return parseInt(option.id) }"

            @input="$emit('eventChanged'); decideCurrentCategory()"
          >
            <template #no-options>{{ $t("pages.middleware_categories.coudnt_find_events") }}</template>
          </v-select>
          <div v-else>
            <b-skeleton type="input"></b-skeleton>
          </div>
        </div>
      </b-col>
      <b-col cols="10" md="4">
        <div class="d-flex align-items-start w-100">
          <div class="">
            <div class="small text-muted text-uppercase font-weight-bolder">
              {{ $t("pages.middleware_categories.current_direction") }}
            </div>
            <div class="mt-1">
              <b-button-group >
                <b-button
                  v-for="item in directionList"
                  :key="`direction-${item.id}`"
                  class=" d-inline whitespace-nowrap"
                  :variant="
                    category.direction.id == item.id
                      ? `secondary`
                      : `outline-secondary`
                  "
                  @click="changeDirection(item.id)"
                >

                  <span v-if="item.id == 1" class="">
                    <b-icon :icon="item.icon" shift-v="1" class="mr-75"/>
                    {{ $t(item.label) }} 
                  </span>
                  
                  <span v-else class="">
                    {{ $t(item.label) }}  
                    <b-icon :icon="item.icon" shift-v="1" class="ml-75"/>
                  </span>  

                </b-button>
              </b-button-group>
            </div>
          </div>
          <div  class="ml-1 w-100"> 
            <div class="small text-muted text-uppercase font-weight-bolder mb-1" align="center">
              OVERVIEW
            </div>
            <div align="center">
              <b-button @click="overviewActive = !overviewActive" class="px-2 py-75 position-relative" variant="outline-dark">

                <span class="position-relative d-block">
                  <b-icon icon="eye" scale="1.2" v-if="overviewActive" variant="dark"/>
                  <b-icon icon="eye-slash" scale="1.2" v-else variant="dark"/>
                </span>
              </b-button>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>

    <div>
      <b-collapse :visible="overviewActive" @shown="transmissionMapVersion = 1; passCategoryToOverview(); getOverviewData();" @show="$refs['realtime-transmission-map'] ? $refs['realtime-transmission-map'].initAnimations() : ''" lazy >
        <div class="transmission-map-container position-relative w-100 pt-1" v-if="transmissionMapVersion==1">
          <transmission-map :isRealTime="true" :shownScript="overviewScript" @putCategory="putCategory" ref="realtime-transmission-map"/>
        </div>
      </b-collapse>
    </div>
    

  </div>
</template>

<script>
  
import { 
  BCol,
  BRow, 
  BButton, 
  BButtonGroup, 
  BSkeleton, 
  BModal, 
  BSpinner, 
  BFormInput, 
  BBadge, 
  BCollapse 
} from "bootstrap-vue";

import vSelect from "vue-select";
import Category from "@/custom/class/Middleware/Category";
import Directions from "@/custom/class/Enum/Directions";
import Direction from "@/custom/class/Middleware/Direction";
import { makeToast } from "@/layouts/components/Popups";
import Categories from "@/custom/class/Enum/Categories";
import Monaco from "@/layouts/components/editor/monaco.vue";
import CreateEventModal from "@/views/pages/middleware/MiddlewareManagement/Components/Modals/CreateEventModal.vue"
import CreateServiceModal from "@/views/pages/middleware/MiddlewareManagement/Components/Modals/CreateServiceModal.vue"
import { mapGetters } from "vuex";
import FiqonLogo from "@/views/pages/middleware/MiddlewareManagement/Components/ComponentSVGs/FiqonLogo.vue";
import TransmissionMap from "@/views/pages/middleware/MiddlewareManagement/Components/FluxOverview/TransmissionMap.vue"

export default {
  components: {
    BCol,
    BRow,
    BButton,
    vSelect,
    BButtonGroup,
    BSkeleton,
    BModal,
    BSpinner,
    BFormInput,
    BBadge,
    Monaco,
    BCollapse,
    CreateEventModal,
    CreateServiceModal,
    FiqonLogo,
    TransmissionMap
  },
  props: {
    value: {
      type: Category,
      required: true,
    },
  },
  data() {
    return {
      option: [
        { title: "Square" },
        { title: "Rectangle" },
        { title: "Rombo" },
        { title: "Romboid" },
      ],
      lastAccessInfo: undefined,
      overviewActive: false,
      overviewScript: undefined,
      transmissionMapVersion: 0,
 
    };
  },
  computed: {
    ...mapGetters(["getTransmissionEvents", "getTransmissionServices"]),
    category: {
      set(value) {
        this.$emit("input", value);
      },
      get() {
        return this.value;
      },
    },
    eventList(){
      if (!this.events || !Array.isArray(this.events)){
        return
      }
      let l = structuredClone(this.events)
      l.sort((a,b) => (a.event_identifier > b.event_identifier) ? 1 : ((b.event_identifier > a.event_identifier) ? -1 : 0))
      return l
    },
    events(){
      return this.getTransmissionEvents
    },
    serviceList(){
      let r = structuredClone(this.getTransmissionServices)

      if (Array.isArray(r)){
        r.sort((a,b) => (a.identifier > b.identifier) ? 1 : ((b.identifier > a.identifier) ? -1 : 0))
      }
      
      return r
    },
    hasService() {
      return (
        this.category.service_id !== null &&
        this.category.service_id !== undefined
      );
    },
    hasEvent() {
      return (
        this.category.event_id !== null && this.category.event_id !== undefined
      );
    },
    directionList() {
      const dir = new Directions();
      return dir.items;
    },
    isFree() {
      return this.category.identifier == 'free';
    },
    isService() {
      return this.category.identifier == 'service';
    },
    isEvent() {
      return this.category.identifier == 'event';
    },
    isServiceEvent() {
      return this.category.identifier == 'service_event';
    },
    userCantEdit(){
      return !this.$can('create')
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getEvents();
      this.getServices();
      this.$nextTick(()=>{
        this.getLastAccess();
      })
      this.getOverviewData();
    },
    getEvents() {
      this.$store
        .dispatch("getEventsByTransmissionGroup", {
          transmissionID: this.$route.params.transmissionID,
        })
        .then((resp) => {
          this.getLastAccess();
        })
        .catch((err) => {
          console.error(err)
          makeToast({
            title: 'Error!',
            text: "Events couldn't be fetched...",
            variant: "danger",
            icon: "XIcon",
          });
        });
    },
    getServices() {
      this.$store
        .dispatch("getServicesByTransmissionGroup", {
          transmissionID: this.$route.params.transmissionID,
        })
        .then((resp) => {
          if (Array.isArray(this.serviceList) && this.serviceList.length == 1){              
            this.category.service_id = parseInt(this.serviceList[0].id);
            this.$emit('serviceChanged');
            this.decideCurrentCategory()
          }
          
          this.getLastAccess();

          // setTimeout(() => {

          // }, 2000);
        })
        .catch((err) => {
          makeToast({
            title: 'Error!',
            text: "Services couldn't be fetched...",
            variant: "danger",
            icon: "XIcon",
          });
        });
    },
    changeDirection(new_dir) {
      if (new_dir == this.category.direction.id){
        return
      }

      this.$set(this.category, "direction", new Direction(new_dir));
      this.$nextTick(() => {
        this.$emit("directionChanged");
      });

      this.passCategoryToOverview()
      this.setLastAccess()
    },
    changeCategory(identifier) {
        const category = (new Categories()).getFromIdentifier(identifier);

        // if (this.category.identifier == category.identifier){
        //   return
        // }

        this.category.id = category.id;
        this.category.identifier = category.identifier;
        this.category.label = category.label;

        this.$nextTick(() => {
          this.$emit('categoryChanged');
        });
        this.passCategoryToOverview()
        this.setLastAccess()
        this.$store.dispatch('internal/selectedEventChanged')
    },
    decideCurrentCategory(){    
      const service = !!this.category.service_id
      const event = !!this.category.event_id
      let r;

      switch (true) {
        case (!service && !event):
          r = new Categories().items.find(el=> el.id == 1)          
          break;
        case (service && !event):
          r = new Categories().items.find(el=> el.id == 2)
          break;
        case (!service && event):
          r = new Categories().items.find(el=> el.id == 3)          
          break;
        case (service && event):
          r = new Categories().items.find(el=> el.id == 4)          
          break;
      }
      this.changeCategory(r.identifier)
    },
    setLastAccess(){
      let r = localStorage.getItem('lastAccessInfo')

      const transmissionID = this.$route.params.transmissionID
      if (!r || r=='null'){
        r = '{}'
      }
      let data = {
        'service_id': this.category.service_id || null,
        'event_id': this.category.event_id || null,
        'dir_id': this.category.direction.id || null
      }
      r = JSON.parse(r)
      r[transmissionID] = data
      localStorage.setItem('lastAccessInfo', JSON.stringify(r))
    },
    async getLastAccess(){      
      if (!this.eventList || !this.serviceList){
        return
      }

      const service = !!this.category.service_id
      const event = !!this.category.event_id
      let r;

      switch (true) {
        case (!service && !event):
          r = new Categories().items.find(el=> el.id == 1)          
          break;
        case (service && !event):
          r = new Categories().items.find(el=> el.id == 2)
          break;
        case (!service && event):
          r = new Categories().items.find(el=> el.id == 3)          
          break;
        case (service && event):
          r = new Categories().items.find(el=> el.id == 4)          
          break;
      }
        

      const category = (new Categories()).getFromIdentifier(r.identifier);

      this.category.id = category.id;
      this.category.identifier = category.identifier;
      this.category.label = category.label;
      

      let lastAccessInfo = localStorage.getItem('lastAccessInfo')
      if (lastAccessInfo && lastAccessInfo.length && (lastAccessInfo!='null')){
        lastAccessInfo = JSON.parse(lastAccessInfo);
        lastAccessInfo = lastAccessInfo[this.$route.params.transmissionID]
        if (lastAccessInfo && lastAccessInfo.dir_id && lastAccessInfo.dir_id != 1){
          this.changeDirection(lastAccessInfo.dir_id)
        }
      }
    },
    getOverviewData(){
      this.$store.dispatch("getTransmissionOverview", this.$route.params.transmissionID)
      .then((resp)=>{
        this.overviewScript = resp
      })
    },
    passCategoryToOverview(){
      if (!this.overviewActive){
        return
      }
      let s = null;
      let e = null;
      let isOut = false;
      if (this.category.service_id){
        s = this.serviceList.find(el=> el.id == this.category.service_id).identifier
      }
      if (this.category.event_id){
        e = this.eventList.find(el => el.id == this.category.event_id).event_identifier
      }
      if (this.category.direction.id == 2){
        isOut = true
      }
      
      let r = {
        service: s,
        event: e,
        isOut: isOut,
      }
      
      this.$nextTick(()=>{
        this.$refs['realtime-transmission-map'].setCategory(r)
      })

    },
    putCategory(itm){
      

      let s = this.serviceList.find(el => el.identifier == itm.service)
      let e = this.eventList.find(el => el.event_identifier == itm.event)
      let c

      if (s){
        s = parseInt(s.id)
      }
      if (e){
        e = parseInt(e.id)
      }

      if (!itm.isOut){
        c = new Direction(1)
      } else {
        c = new Direction(2)
      }


      this.category.service_id = s || null
      this.category.event_id = e || null
      this.category.direction = c
      
      this.decideCurrentCategory()
    },
    serviceCreated(s){
      this.$set(this.category, 'service_id', s.id)
      this.$emit('serviceChanged'); 
      this.decideCurrentCategory()
    },
    eventCreated(e){
      this.$set(this.category, 'event_id', e.id)
      this.$emit('eventChanged'); 
      this.decideCurrentCategory()
    }
  },
};
</script>

<style lang="scss" scoped>
.whitespace-nowrap{
  white-space: nowrap;
}

.transmission-map-container{
  height: fit-content;
}
</style>

<style lang="scss">

.border-bottom-favorite{
  border-bottom: 1px solid var(--favorite);
}

.middleware-flux-header-select-category{
  .vs__clear{
    transform: scale(1.1);
    border-radius: 20px;
    padding: 3px 6px;
    transition: transform 0.2s, scale 0.1s, ;
    filter: drop-shadow(0 0  4px rgba(0, 0, 0, 0.6)) hue-rotate(0deg) saturate(1); 
    
    &:hover{
      scale: 1.1;
      filter: drop-shadow(0 0  4px rgba(0, 0, 0, 0.6)) hue-rotate(110deg) saturate(3) drop-shadow(0 0  5px var(--dark-blue)) drop-shadow(0 0  2px rgba(0, 0, 0, 0.4)); 
    }
  }
}
</style>

