export default class EnumSources {
  constructor() {
    this.items = [
      {
        id: 1,
        operation: 'Opens the compilation window',
        keyCombination: ['ctrl', 'shift', "s"]
      },
      {
        id: 1,
        operation: 'Toggles the Rearrangement Overlay',
        keyCombination: ['ctrl', 'space']
      },
      {
        id: 1,
        operation: 'Flux reload',
        keyCombination: ['ctrl', 'esc']
      },
      {
        id: 1,
        operation: 'Test Terminal quick run',
        keyCombination: ['ctrl', 'shift', "r"]
      },
      {
        id: 1,
        operation: 'Flux Search',
        keyCombination: ['ctrl', 'shift', "f"]
      },
      {
        id: 1,
        operation: 'Toggles the Middleware/Integrator Drag sidebar',
        keyCombination: ['shift', "tab"]
      },
      {
        category: 'Rearrangement Overlay',
        items: [
          {
            id: 1,
            operation: 'Clear selected Middlewares',
            keyCombination: ['esc']
          },
          {
            id: 1,
            operation: 'Selects all Middlewares',
            keyCombination: ['shift', "a"]
          },
          {
            id: 1,
            operation: 'Reorders selected Middlewares (Cut & Paste)',
            keyCombination: ['ctrl', "x"]
          },
          {
            id: 1,
            operation: 'Copies selected Middlewares to Clipboard',
            keyCombination: ['ctrl', "c"]
          },
          {
            id: 1,
            operation: 'Pastes Middlewares in Clipboard',
            keyCombination: ['ctrl', "v"]
          },
          {
            id: 1,
            operation: 'Deletes selected Middlewares',
            keyCombination: ['del']
          },
          {
            id: 1,
            operation: '[HOLD] allows for multi-select of Middlewares',
            keyCombination: ['shif']
          },
        ]
      },
    ]
  }
}
