import AgentsMixin from '../AgentsMixin'

export default {
  mixins: [AgentsMixin],
    AgentAdd: () => import("./Add.vue"),
    AgentSubtract: () => import("./Subtract.vue"),
    AgentRemap: () => import("./Remap.vue"),
    AgentEqual: () => import("./Equal.vue"),
    AgentNotEqual: () => import("./NotEqual.vue"),
    AgentGreater: () => import("./Greater.vue"),
    AgentGreaterEqual: () => import("./GreaterEqual.vue"),
    AgentLessThan: () => import("./LessThan.vue"),
    AgentLessThanEqual: () => import("./LessThanEqual.vue"),
    AgentDelete: () => import("./Delete.vue"),
    AgentArrayAppend: () => import("./ArrayAppend.vue"),
    AgentArrayFilter: () => import("./ArrayFilter.vue"),
    AgentArrayMax: () => import("./ArrayMax.vue"),
    AgentArrayMin: () => import("./ArrayMin.vue"),
    AgentConcatenate: () => import("./Concatenate.vue"),
    AgentExecuteJavascript: () => import("./ExecuteJavascript.vue"),
    AgentExists: () => import("./Exists.vue"),
    AgentNotExists: () => import("./NotExists.vue"),
    AgentXmlDecode: () => import("./XmlDecode.vue"),
    AgentXmlEncode: () => import("./XmlEncode.vue"),
    AgentJsonDecode: () => import("./JsonDecode.vue"),
    AgentJsonEncode: () => import("./JsonEncode.vue"),
    AgentStringCaseParser: () => import("./StringCaseParser.vue"),
    AgentStringReplace: () => import("./StringReplace.vue"),
    AgentThirdPartyToken: () => import("./ThirdPartyToken.vue"),
    AgentMultiplication: () => import("./Multiplication.vue"),
    AgentDivision: () => import("./Division.vue"),
    AgentMapKeys: () => import("./MapKeys.vue"),
    AgentDebugPayload: () => import("./DebugPayload.vue"),
    AgentArrayLength: () => import("./ArrayLength.vue"),
    AgentMod: () => import("./Mod.vue"),
    AgentLogicExpression: () => import("./LogicExpression.vue"),
    AgentBooleanExpression: () => import("./BooleanExpression.vue"),
    AgentFileWrite: () => import("./FileWrite.vue"),
    AgentFileRead: () => import("./FileRead.vue"),
    AgentFileSeek: () => import("./FileSeek.vue"),
    AgentRegexMatch: () => import("./RegexMatch.vue"),
    AgentFileReadLine: () => import("./FileReadLine.vue"),
    AgentStringSection: () => import("./StringSection.vue"),
    AgentSetStatusCode: () => import("./SetStatusCode.vue"),
    AgentDateToTimestamp: () => import("./DateToTimestamp.vue"),
    AgentIsNull: () => import("./IsNull.vue"),
    AgentStringToFloat: () => import("./StringToFloat.vue"),
    AgentStringTrim: () => import("./StringTrim.vue"),
    AgentTimestampAdd: () => import("./TimestampAdd.vue"),
    AgentTimestampToDate: () => import("./TimestampToDate.vue"),
    AgentLogger: () => import("./Logger.vue"),
    AgentDelay: () => import("./Delay.vue"),
    AgentRandomInteger: () => import("./RandomInteger.vue"),
    AgentRandomString: () => import("./RandomString.vue"),
    AgentUuid: () => import("./Uuid.vue"),
    AgentSetResponseHeader: () => import ("./SetResponseHeader.vue"),
    AgentGetResponseHeader: () => import ("./GetResponseHeader.vue"),
    AgentDeleteResponseHeader: () => import ("./DeleteResponseHeader.vue"),
    AgentStringExplode: () => import("./StringExplode.vue"),
    AgentVerifyTrue: () => import("./VerifyTrue.vue"),
    AgentVerifyFalse: () => import("./VerifyFalse.vue"),
    AgentStringLength: () => import('./StringLength.vue'),
    AgentStringInsert: () => import('./StringInsert.vue'),
    AgentUrlEncode: () => import('./UrlEncode.vue'),
    AgentUrlDecode: () => import('./UrlDecode.vue'),
    AgentDeleteDuplicate: () => import('./DeleteDuplicate.vue'),
    AgentRemapArrayValue: () => import('./ArrayRemapValue.vue'),
    AgentGetStorageRecord: () => import('./GetStorageRecord.vue'),
    AgentSetStorageRecord: () => import('./SetStorageRecord.vue'),
    AgentDeleteStorageRecord: () => import('./DeleteStorageRecord.vue'),
    AgentBase64Encode: () => import('./Base64Encode.vue'),
    AgentBase64Decode: () => import('./Base64Decode.vue'),
    AgentGenerateHash: () => import('./GenerateHash.vue'),
    AgentRegexReplace: () => import('./RegexReplace.vue')
}