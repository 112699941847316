<template>
  <span 
    title="draggable"
    draggable 
    @dragstart.self="onDrag" 
    @dragover.prevent
  >
    <slot />
  </span>
</template>
    
    <script>
export default {
  props: {
    transferData: {
      type: Object,
      required: true,
    },
    pragmaticOrigin: {
      type: String,
      default: "default"
    }
  },
  methods: {
    onDrag(e) {
      e.dataTransfer.effectAllowed = "move";
      e.dataTransfer.dropEffect = "move";
      e.dataTransfer.setData("payload", JSON.stringify(this.transferData));
      e.dataTransfer.setData("pragmaticOrigin", this.pragmaticOrigin)
    },
  },
};
</script>
    
<style lang="css" scoped>
</style>