import { render, staticRenderFns } from "./MiddlewareListDrag.vue?vue&type=template&id=2ea13d14&scoped=true"
import script from "./MiddlewareListDrag.vue?vue&type=script&lang=js"
export * from "./MiddlewareListDrag.vue?vue&type=script&lang=js"
import style0 from "./MiddlewareListDrag.vue?vue&type=style&index=0&id=2ea13d14&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ea13d14",
  null
  
)

export default component.exports