export default class MiddlewareCategories {
  constructor() {
    this.items = {
      
      requester: {
        label: "enum.middleware.category.requester",
        icon: "cursor",
        variant: "info",
      },
      utility: {
        label: "enum.middleware.category.utility",
        icon: "tools",
        variant: "success",
      },
      connectivity: {
        label: "enum.middleware.category.connectivity",
        icon: "globe2",
        variant: "info",
      },
      internal: {
        label: "enum.middleware.category.internal",
        icon: "house",
        variant: "light",
      },
      loop: {
        label: "enum.middleware.category.loop",
        icon: "arrow-repeat",
        variant: "light",
      },
      logic: {
        label: "enum.middleware.category.logic",
        icon: "diagram2",
        variant: "light",
      },
      ftp: {
        label: "enum.middleware.category.ftp",
        icon: "file-earmark",
        variant: "warning",
      },
      mysql: {
        label: "enum.middleware.category.mysql",
        icon: "server",
        variant: "warning",
      },
      mongo: {
        label: "enum.middleware.category.mongo",
        icon: "server",
        variant: "warning",
      },
      postgressql: {
        label: "enum.middleware.category.postgressql",
        icon: "server",
        variant: "warning",
      },
      editor: {
        label: "enum.middleware.category.editor",
        icon: "file-code",
        variant: "danger",
      },
      database:{
        label: "enum.middleware.category.database",
        icon: "server",
        variant: "warning",
      }
    };
  }
}
