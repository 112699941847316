<template>
  <div>
    <b-modal
      ref="shortcuts-modal"
      hide-footer
      size="lg"
      body-class="shortcuts-modal-body"
      scrollable
    >
      <template #modal-title>
        <b-icon icon="keyboard" class="mr-1" scale="2" shift-v="1"/>
        <span class="font-weight-bolder">
          Flux Shortcuts
        </span>
      </template>

      <div class="shortcuts-container">
        <div v-for="shortcut in shortcuts">
          <div v-if="!shortcut.category" class="py-1">
            <span>
              <span v-for="(key, keyIdx) in shortcut.keyCombination">
                <b-icon icon="plus" variant="secondary" class="" scale="1.2" v-if="keyIdx != 0" />
                <span class="key-activation">{{ key.length == 1 ? key.toUpperCase() : key}}</span>
              </span>
            </span>
            
            <b-icon icon="arrow-right-short" class="mx-1" scale="1.4" variant="success"/> 
            <span class="font-weight-bolder">
              {{ shortcut.operation }}
            </span>
          </div>
          <div v-else class=" border-secondary rounded py-1 mt-2">

            <h4 class="mb-0 ml-1">{{shortcut.category}}</h4>

            <div v-for="catShortcut in shortcut.items" class="py-1 px-1">
              <span>
                <span v-for="(key, keyIdx) in catShortcut.keyCombination">
                  <b-icon icon="plus" variant="secondary" class="" scale="1.2" v-if="keyIdx != 0" />
                  <span class="key-activation">{{ key.length == 1 ? key.toUpperCase() : key}}</span>
                </span>
              </span>
              
              <b-icon icon="arrow-right-short" class="mx-1" scale="1.4" variant="success"/> 
              <span class="font-weight-bolder">
                {{ catShortcut.operation }}
              </span>
            </div>

          </div>
        </div>
      </div>

    </b-modal>
  </div>
</template>


<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Carousel, Slide } from 'vue-carousel';

import {
  BCard,
  BCardText,
  BCardBody,
  BLink,
  BButton,
  BCol,
  BRow,
  BSkeleton,
  BSkeletonImg,
  BFormInput,
  BInputGroup, 
  BInputGroupPrepend,
  BBadge,
  BPagination,
  BAvatar,
  BSpinner,
  BFormDatepicker,
  BTooltip,
  BFormSelect
} from "bootstrap-vue";
import KeyboardShortcuts from "@/custom/class/Enum/KeyboardShortcuts.js"

import { mapGetters } from 'vuex';

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BButton,
    BRow,
    BCol,
    ValidationObserver,
    ValidationProvider,
    BSkeleton,
    Carousel,
    Slide,
    BSkeletonImg,
    BCardBody,
    BFormInput,
    BInputGroup, 
    BInputGroupPrepend,
    BBadge,
    BPagination,
    BAvatar,
    BSpinner,
    BFormDatepicker,
    BTooltip,
    BFormSelect,
    KeyboardShortcuts,
  },
  data() {
    return {
      shortcuts: new KeyboardShortcuts().items
    };
  },
  props: {
    
  },
  mounted() {
    this.init()
  },
  methods: {
    init(){
    },
    show(){
      this.$refs['shortcuts-modal'].show()
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/custom-utils.scss';
.shortcuts-modal-body{
  border-top: 1px solid $black !important;
  background-color: transparentize($black, 0.6) !important;
}

</style>

<style lang="scss" scoped>
@import "@/assets/scss/custom-utils.scss";

.shortcuts-container{
  border-radius: 5px;
  padding: 8px 10px;

  .key-activation{
    padding: 2px 5px;
    background-color: $secondary;
    background-color: $favorite;
    color: $black;
    font-weight: 600;
    border-radius: 3px;
    margin: 0 5px;
    box-shadow: 4px 4px black;
  }
}

</style>
