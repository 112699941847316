export default class HelperCategories {
  constructor() {
    this.items = [
      {
        // 0 is a special Category that defines if the Helper is set as Private or not
        // private Helpers can only be seen by the user that created them
        label: 'Private',
        description: "Private Templates can only be seen by you",
        color: '#171c2b',
        icon: "lock-fill",
        id: '0',
      },
      {
        label: 'Data treatment',
        description: "Middlewares that reformulate data in some way",
        color: '#3e4454',
        icon:"tools",
        id: '1',
      },
      {
        label: 'Javascript',
        description: "Middlewares defined by specific Javascript operations",
        color: 'rgb(164, 137, 33)',
        icon:"code-slash",
        id: '2',
      },
      {
        label: 'Loop',
        description: "Middlewares in looping structures",
        color: 'rgb(149, 37, 37)',
        icon:"arrow-repeat",
        id: '3',
      },
      {
        label: 'Request',
        description: "Middlewares that execute requests",
        color: 'rgb(48, 42, 125)',
        icon:"cursor-fill",
        id: '4',
      },
      {
        label: 'Database',
        description: "Middlewares that perform operations on databases",
        color: 'rgb(33, 72, 147)',
        icon:"server",
        id: '5',
      },
      {
        label: 'Template',
        description: "Middleware structure that serves as template for some operation",
        color: 'rgb(30, 100, 132)',
        icon:"puzzle-fill",
        id: '6',
      },
      {
        label: 'Other',
        description: "Middlewares of miscellaneous uses",
        color: '#676c7d',
        icon:"dice4-fill",
        id: '7',
      },
    ]
  }
}