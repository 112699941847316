<template>
  <div class="pb-3">
    <div class="d-flex justify-content-between">
      <div/>
      <div align="right" class="mr-1 d-flex justify-content-end">
        <span class="p-50  py-50 cursor-pointer mr-25" @click="closedCats= cloneMiddlewareCategories()">
          <b-icon icon="fullscreen-exit" scale="1" class=""/>
        </span>
        <span class="p-50 py-50 cursor-pointer" @click="closedCats = []">
          <b-icon icon="arrows-fullscreen" scale="0.9" class=""/>
        </span>
      </div>
    </div>
    <div v-for="cat in middlewareCategories" class="mb-1" :key="'mid-category'+cat">
      <div v-if="filteredMiddlewareList(cat).length" class="bordered-success mx-50">
        <div class="d-flex justify-content-start align-items-end category-collapse-btn pl-50 py-25" @click="toggleCollapse(cat)">
          <b-icon icon="caret-down-fill" scale="0.8" variant="light" class="mid-category-collapse-icon mx-25 mb-25" :class="closedCats.includes(cat) ? 'rotate180' : ''"/>
          <h5 class="mb-0 font-weight-bolder">
            {{$t(`enum.middleware.category.${cat}`)}}
            <b-icon :icon="getCategoryItem(cat).icon" class="ml-50" scale="1" variant="secondary"/>
          </h5>
        </div>

        <b-collapse :visible="!closedCats.includes(cat)">
          <div class="px-25">
            <li class="enum-middleware-list cursor-pointer mx-50" v-for="middleware in filteredMiddlewareList(cat)" :key="'draggable-middleware-'+middleware.id">
              <drag
                pragmaticOrigin="middleware"
                :key="'drag-'+middleware.id"
                :transferData="{ item: middleware, originID }"
                >
                <div class="w-100 border rounded p-75 draggable-item">
                  <b-iconstack font-scale="1.17" style="opacity: 0.4" class="mr-1">
                    <b-icon
                    shift-h="-5"
                    icon="three-dots-vertical"
                    />
                    <b-icon
                    icon="three-dots-vertical"
                    />
                  </b-iconstack>
                  <b-icon
                  :icon="middleware.icon"
                  class="mr-1"
                  :class="`text-${middleware.variant || 'success'}`"
                  scale="1.3"
                  :variant="cat.variant"
                  />
                  <b style="font-size: 15px">
                    {{$t(`middleware.list.${middleware.i18key}.name`)}}
                  </b>
                </div>
              </drag>
            </li>
          </div>
        </b-collapse>
      </div>
    </div>
    <div class="d-flex align-items-center mx-1 justify-content-center">
      <span class="font-weight-bolsd small text-secondary">Use <b>Shift + Tab</b> to Toggle Sidebar</span>
    </div>
  </div>
</template>

<script>
import Drag from '@/views/pages/middleware/MiddlewareManagement/Components/Drag.vue'
import MiddlewareEnum from '@/custom/class/Enum/Middlewares'
import { 
  BCollapse,
  BButton,
  BFormCheckbox,
  BIconstack
} from "bootstrap-vue";
import MiddlewareCategories from '@/custom/class/Enum/Map/MiddlewareCategories.js'

export default {
    components: {
      Drag,
      BCollapse,
      BButton,
      BFormCheckbox,
      BIconstack
    },
    data() {
      return {
        originID: 1,
        term: null,
        closedCats: []
      }
    },
    computed: {
      middlewareList() {
        return new MiddlewareEnum().items.filter(el => el.active == true) 
      },
      middlewareCategories(){
        let items = this.middlewareList;
        let r = []
        items.forEach(mid => {
          if (!r.includes(mid.category)){
            r.push(mid.category)
          }
        });
        return r;
      },
    },
    mounted () {
      this.init();
    },
    methods: {
      init(){
        
      },
      search(term) {
        this.term = term
      },
      filteredMiddlewareList(cat) {
        let midList = structuredClone(this.middlewareList)
        if (cat){
          midList =  midList.filter(el =>
            { return el.category == cat}
          )
        }

        if (this.term) {
          midList =  midList.filter(el =>
            {
              return (
                this.$t(`middleware.list.${el.i18key}.name`).toLowerCase().indexOf(this.term.toLowerCase()) > -1
                  ||
                this.$t(`enum.middleware.category.${el.category}`).toLowerCase().indexOf(this.term.toLowerCase()) > -1                
              )
            }
          )
        }
        
        return midList
      },
      toggleCollapse(cat){
        let r = this.closedCats
        if (r.includes(cat)){
          let idx = r.indexOf(cat)
          if (idx > -1){
            r.splice(idx, 1)
          }
        } else {
          r.push(cat)
        }
        this.closedCats = r
      },
      cloneMiddlewareCategories(){
        return structuredClone(this.middlewareCategories)
      },
      getCategoryItem(cat){
        let items = new MiddlewareCategories().items
        return items[cat]
      }
      
    },
  }
</script>

<style lang="scss" scoped>

.draggable-item{
  transition: all 0.2s;
  cursor: grab;
  outline: 1px solid transparent;
  &:hover{
    outline: 1px solid rgba(255, 255, 255, 0.2);
  }
}
.enum-middleware-list {
  list-style-type: none;
  margin: 5px 0 5px 0;
}
.bordered-success{
  border-left: 2px solid #286c56;
}
.category-collapse-btn{
  cursor: pointer;
  transform: all 0.3s;
  &:hover{
    //background-color: rgba(0, 0, 0, 0.1);
    box-shadow: inset 0px -30px 30px -10px rgba(0,0,0,0.15), -2px 0px 0px 0px var(--success);  

  }
}

.mid-category-collapse-icon{
  transition: all 0.3s;
  transform: rotate(0deg);
}
.rotate180{
  transform: rotate(-90deg);
}
</style>