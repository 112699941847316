<template>
<div v-if="$can('create')">
  <div class="helpers-sidebar-outer" :class="{'collapsed-sidebar': !show}">
    <b-button variant="none" class="toggle-sidebar-btn" @click="toggleSidebar()">
      <div>
        <b-icon icon="layout-wtf" scale="0.8" class="mb-25 d-inline"/>
        Templates
      </div>
    </b-button>
    
    <div class="content-container">
      <div>
        <TemplatesListContent 
          v-if="!isFullyClosed"
          ref="templates-list-content" 
          @forceImport="importMiddlewares"
          @openAddTemplateModal="openAddTemplateModal()" 
          @openEditTemplateModal="openEditTemplateModal"
        />
      </div>
    </div>
  </div> 

  <manage-template-modal
    ref="manage-template-modal"
  />
</div>
</template>

<script>

import {
  BRow,
  BCol,
  BCard,
  BButton,
  BSpinner,
  BContainer,
  BSkeleton,
  BCollapse,
  BBadge,
  BOverlay,
  BFormInput,
  BTooltip,
  VBTooltip,
  VBToggle,
  BFormTextarea,
} from "bootstrap-vue";
import TemplatesListContent from "@/views/pages/middleware/MiddlewareManagement/TemplatesListContent.vue";
import ManageTemplateModal from "@/views/pages/middleware/MiddlewareManagement/ManageTemplateModal.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BSpinner,
    BContainer,
    BSkeleton,
    BCollapse,
    BBadge,
    BOverlay,
    BFormInput,
    BTooltip,
    VBTooltip,
    VBToggle, 
    TemplatesListContent,
    BFormTextarea,
    ManageTemplateModal,
  },
  data() {
    return {
      show: false,
      isFullyClosed: true,
      timeout: undefined,
    }
  },
  methods: {
    toggleSidebar(state=null){
      if (state == null){
        this.show = !this.show
      } else {
        this.show = state
      }

      clearTimeout(this.timeout)
      if (this.show == true){        
        this.isFullyClosed = false
      } else {
        this.timeout = setTimeout(() => {
          this.isFullyClosed = true
        }, 600);
      }
      this.$refs['templates-list-content']?.sidebarToggled(this.show)
    },
    importMiddlewares(compressed){
      this.toggleSidebar(false)
      this.$emit('forceImport', compressed)
    },
    openAddTemplateModal(){
      this.$refs['manage-template-modal'].openAddTemplateModal()
    },
    openEditTemplateModal(template){
      this.$refs['manage-template-modal'].openEditTemplateModal(template)
    }
  },
}
</script>

<style lang="scss" scoped>
$sidebar-bg: rgb(74, 90, 110);
$sidebar-bg: #303a57;
$sidebar-bg: #252d44;
$sidebar-boder-color: rgb(45, 63, 90);
$sidebar-boder-color: rgb(67, 76, 92);
$sidebar-width: 26vw;
$sidebar-min-width: 500px;

.helpers-sidebar-outer{
  position: fixed;
  right: 0;
  top: 5%;
  //height: 850px;
  height: 95%;
  width: $sidebar-width;
  min-width: $sidebar-min-width;
  max-width: $sidebar-width;
  background-color: $sidebar-bg;
  z-index: 16;
  border-radius: 5px 0 0 0;
  border: 2px solid $sidebar-boder-color;
  transition: max-width 0.5s ease-out, min-width 0.5s ease-out, height 0.1s;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  &.collapsed-sidebar{
    z-index: 11;
    max-width: 23px;
    min-width: 0;
    height: 850px;
  } 
}


.toggle-sidebar-btn{
  position: absolute;
  left: 0;
  transform: translateX(-100%);
  top: calc(15% + 150px);
  //top: 30%;
  padding: 10px 0px 10px 4px;
  font-size: 15px;
  border: 1px solid $sidebar-boder-color;
  border-right: none;
  color: white;
  background-color: $sidebar-bg !important;
  border-radius: 4px 0px 0px 4px;
  z-index: 10;
  width: 1.6em;
  transition: all 0.1s;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.4);
  >div{
    writing-mode: vertical-lr; 
    text-orientation: mixed;
  }
  &:hover{
    padding: 13px 0px 13px 6px;
    width: 1.8em;
  }
}

.content-container{
  width: $sidebar-width;
  min-width: $sidebar-min-width;
  background-color: $sidebar-bg;
  padding: 15px;
  height: 100%;
  overflow-y: auto;
}
</style>