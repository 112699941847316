<template>
<div>
  <b-modal
    ref="export-middlewares-modal"
    centered
    hide-footer
    body-class="round"
    header-class="rounded"
  >
    <template #modal-title>
      <div>
        <h5 class="m-0">
          <feather-icon icon="GitPullRequestIcon" />
          Middleware Export
        </h5>
      </div>
    </template>
    
    <div align="right" class="mb-50">
      <b-button class="py-25 px-1" variant="outline-light" @click="exportToTemplate()">
        <span class="small font-weight-bolder">
          <b-icon icon="plus-circle"/>
          Create Template from Middlewares
        </span>
      </b-button>
    </div>
    <hr>
    <p class="font-weight-bolder">By File:</p>
    <!-- <div class="mb-2">
      <b-form-file v-model="importFile" class="">
      </b-form-file>
    </div> -->
    <span>Name:</span>
    <b-form-input v-model="exportMiddlewareFileName"></b-form-input>

    <div class="exported-file-download-btn mb-2" @click="downloadExportFile()">
      <div class="d-flex justify-content-center py-2 mt-1">
        <b-iconstack scale="1.2">
          <b-icon icon="file-earmark-fill" scale="3.5" variant="success"/>
          <b-icon icon="file-earmark" scale="3.3.5" variant="black"/>
        </b-iconstack>
        <!-- <img src="/img/logo.a7407df7.svg" alt="logo" height="20px" width="20px" class="fiqon-icon-logo"> -->
        <svg class="fiqon-icon-logo" id="svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25" height="25" viewBox="0, 0, 400,526.15894039735105">
          <g id="svgg">
              <path id="path0" d="M145.471 6.225 C 145.943 14.965,150.856 123.162,150.786 123.253 C 150.754 123.294,124.412 113.571,92.248 101.645 C 60.084 89.720,33.703 80.031,33.624 80.114 C 33.364 80.386,-0.000 182.053,0.005 182.556 C 0.008 182.826,27.060 190.413,60.121 199.416 C 93.181 208.419,120.141 215.929,120.031 216.105 C 119.921 216.280,102.410 237.997,81.119 264.363 C 50.701 302.032,42.547 312.440,43.058 312.941 C 43.416 313.292,63.349 327.658,87.354 344.865 L 130.999 376.152 165.115 325.176 C 183.878 297.139,199.440 273.991,199.696 273.735 C 199.963 273.468,214.762 295.156,234.467 324.695 C 255.352 356.001,269.000 375.983,269.354 375.768 C 269.673 375.575,289.661 361.264,313.772 343.967 L 357.611 312.517 344.719 296.523 C 337.628 287.727,320.107 266.006,305.783 248.254 C 291.459 230.502,279.798 215.920,279.870 215.848 C 279.942 215.776,306.822 208.398,339.603 199.453 C 372.384 190.508,399.391 183.020,399.617 182.812 C 399.930 182.526,367.003 80.887,366.342 80.100 C 366.266 80.009,339.894 89.694,307.738 101.624 C 275.581 113.553,249.233 123.283,249.187 123.246 C 249.086 123.166,254.090 11.986,254.513 4.901 L 254.806 0.000 199.971 0.000 L 145.136 0.000 145.471 6.225 " stroke="none" fill="#000000" fill-rule="evenodd">
              </path>
          </g>
        </svg>
      </div>
      <div align="center">
        <h5 class="text-center mt-25 mb-0 ">{{exportMiddlewareFileName}}.fiqon</h5> 
      </div>
    </div>
    
    <!-- <hr class="my-2"> -->

    <div>
      <div class="d-flex justify-content-between align-items-end">
        <p class="font-weight-bolder p-0 mb-0 mx-25">By Text:</p>
        <b-button class="py-25 px-50" variant="outline-secondary" @click="copyToClipboard(compressedCopiedMiddlewares)">
          <b-icon icon="clipboard"/>
          Copy
        </b-button>
      </div>
      <b-form-textarea  class="mt-1 exported-text-textarea"  readonly :value="compressedCopiedMiddlewares"/>
      
    </div>
    
    
  </b-modal>
<manage-template-modal ref="manage-template-modal"/>
</div> 
  
</template>

<script>
import { mapGetters } from 'vuex';
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import * as StringCompressor from '@/custom/class/FunctionClasses/StringCompressor.js'
import FluxClipboardMiddlewareDisplay from '@/views/pages/middleware/MiddlewareManagement/RearrangementOverlay/FluxClipboardMiddlewareDisplay.vue';
import Drag from '@/views/pages/middleware/MiddlewareManagement/Components/Drag.vue'
import ManageTemplateModal from '@/views/pages/middleware/MiddlewareManagement/ManageTemplateModal.vue';
import {
  BButton,
  BCard,
  BTabs,
  BTab,
  BFormInput,
  BIcon,
  BCollapse,
  BModal,
  BFormFile,
  BFormTextarea,
  BIconstack
  
} from 'bootstrap-vue'
import { makeToast } from '@/layouts/components/Popups';

export default {
  components: {
    BButton,
    BCard,
    BTabs,
    BTab,
    BFormInput,
    BIcon,
    BCollapse,
    BModal,
    VuePerfectScrollbar,
    BFormFile,
    BFormTextarea,
    FluxClipboardMiddlewareDisplay,
    Drag,
    BIconstack,
    ManageTemplateModal,
  },
  props: {
    middlewaresToExport:{  
      type: [Array,Object],
      default: ()=>[]       
    }
  },
  data() {
    return {
      exportMiddlewareFileName: 'Middlewares',
    }
  },
  computed: {
    ...mapGetters(["getSelectedMiddlewares"]),
    compressedCopiedMiddlewares(){
      let r = JSON.stringify(structuredClone(this.middlewaresToExport))
      return StringCompressor.compressToBase64(r)
    },
  },
  methods: {
    show(){
      this.$refs['export-middlewares-modal'].show()
    },
    copyToClipboard(txt){
      makeToast({
        title: "Success!",
        text: "Copied to Clipboard!",
        variant: "success",
        icon: "CopyIcon",
      })   
      navigator.clipboard.writeText(txt);
    },
    downloadExportFile() {
        let filename = `${this.exportMiddlewareFileName}.fiqon`
        let data = this.compressedCopiedMiddlewares
        const blob = new Blob([data], {type: 'text/csv'});
        if(window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, filename);
        }
        else{
          const elem = window.document.createElement('a');
          elem.href = window.URL.createObjectURL(blob);
          elem.download = filename;        
          document.body.appendChild(elem);
          elem.click();        
          document.body.removeChild(elem);
        }
        makeToast({
          title: "Downloading...",
          text: `${this.exportMiddlewareFileName}.fiqon started downloading`,
          variant: "secondary",
          icon: "DownloadIcon",
        })   
    },
    exportToTemplate(){
      this.$refs['export-middlewares-modal'].hide()
      this.$refs['manage-template-modal'].openAddTemplateModal(this.compressedCopiedMiddlewares)
    }
  },
}
</script>

<style lang="scss" scoped>

.fiqon-icon-logo{
  color: red !important;
  position: absolute;
}
.exported-file-download-btn{
  cursor: pointer;
  &:hover{
    text-decoration: underline;
    filter: brightness(1.2);
  }
}
.exported-text-textarea{
  min-height: 100px;
}
  .paste-from-clipboard-btn{
    width: 100%;
    margin-top: 10px;
    letter-spacing: 2px;
    padding: 5px 0;
    border-radius: 5px;
    font-weight: bolder;
  }
</style>