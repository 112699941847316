import { render, staticRenderFns } from "./TemplatesListSidebar.vue?vue&type=template&id=7d36dca9&scoped=true"
import script from "./TemplatesListSidebar.vue?vue&type=script&lang=js"
export * from "./TemplatesListSidebar.vue?vue&type=script&lang=js"
import style0 from "./TemplatesListSidebar.vue?vue&type=style&index=0&id=7d36dca9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d36dca9",
  null
  
)

export default component.exports