<template>
  <div>
        <div v-for="(mid, index) in middlewares" :key="`middleware-clipboard-${index}-${uuid}`" class="ml-2 position-relative">

        <div v-if="mid.enum_type.has_child" class="middleware-conector-line" />

          <!-- card ========== -->
          <div class="middleware-card" :class="{inactive: !mid.is_active}">
            <div class="d-flex align-items-center justify-content-start">
              <div class="middleware-icon">
                <b-icon :icon="getMiddlewareIcon(mid.enum_type.id)" scale="1"/>
              </div>
              <div class="position-relative">
                <div class="font-weight-bolder middleware-name">{{mid.name}}</div>
                <div class="text-secondary smaller-text">{{$t(mid.enum_type.label)}}</div>
              </div>
            </div>
          </div>

          <div class="middleware-arrow-container" v-if="!((index == middlewares.length-1) && !isChild)">
            <b-icon icon="arrow-down-short" class="m-0" shift-v="-5" scale="1.3" variant="success"/>
          </div>
          <!-- card ========= -->

        
          <template v-if="mid.enum_type.has_child">
            <flux-clipboard-middleware-display :isChild="true" :middlewares="mid.middlewares"/>
          </template>
        
        </div>



        

  </div> 
  
</template>

<script>
import { mapGetters } from 'vuex';
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import * as StringCompressor from '@/custom/class/FunctionClasses/StringCompressor.js'
import Middlewares from '@/custom/class/Enum/Middlewares.js'
import { v4 as uuidv4 } from 'uuid'

import {
  BButton,
  BCard,
  BTabs,
  BTab,
  BFormInput,
  BIcon,
  BCollapse,
  BModal,
  BFormFile,
  BFormTextarea
  
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BCard,
    BTabs,
    BTab,
    BFormInput,
    BIcon,
    BCollapse,
    BModal,
    VuePerfectScrollbar,
    BFormFile,
    BFormTextarea,
    FluxClipboardMiddlewareDisplay: () => import('@/views/pages/middleware/MiddlewareManagement/RearrangementOverlay/FluxClipboardMiddlewareDisplay.vue')
  },
  props: {
    middlewares:{
      type: Array,
      required: true
    },
    isChild:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      uuid : uuidv4()
    }
  },
  computed: {
    ...mapGetters(["getSelectedMiddlewares"]),
  },
  methods: {
    getMiddlewareIcon(id){
      let mids = new Middlewares().items
      let r = mids.find(el=> el.id == id)
      return r.icon
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom-utils.scss';
*{
  --middleware-width: 200px
}
.middleware-card{
  $card-color:#40485f;
  overscroll-behavior: contain !important;
  background-color: $card-color;
  border-radius: 4px;
  margin-top: 0;
  padding: 2px 5px;
  width: var(--middleware-width);
  margin-top: 10px;
  &.inactive{
    outline: 1px dashed white;
    //opacity: 0.7;
    background-color: transparentize($card-color, 0.8);
  }

  .inactive-flag{
    position: absolute;
    right: -0px;
    //top: 50%;
    //transform: translate(100%, -50%);
    bottom: 0;
    color: $black;
    border-radius: 5px;
    
  }
  
  .middleware-name{    
    max-width: 160px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
  }
  .middleware-icon{
    border: 1px solid var(--secondary);
    padding: 2px 4px;
    margin-right: 5px;
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.05);
  }
  font-size: 12px;
  .smaller-text{
    font-size: 10px;
  }
}

.middleware-arrow-container{
  width: var(--middleware-width);
  display: flex;
  justify-content: center;
}
.middleware-conector-line{
  position: absolute;
  height: 100%;
  border-left: 2px solid var(--secondary) !important;
  top: 20px;
  z-index: -1;
  left: 10px;
}

</style>