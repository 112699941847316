<template>
<div class="px-1">
  <div>
    <ul
      v-if="middlewareList"
      class="mid-list-container pl-0"
    >
      <div v-if="root" class="zoom-it">
        <b-card style="width: 450px" class="text-center mb-25">
          <strong class="text-primary">
            START
          </strong>
        </b-card>
        <div :class="middlewareList[0] && getReorderingMiddlewares.selectedIDs.includes(middlewareList[0].id) ?'force-disable' : ''">
          <svg-arrow 
            class="mt-25"
            direction="down"
            scale="0.5"
            :width="arrowWidth['down']"
            height="70"
            event="addMiddleware"
            @addMiddleware="chooseMiddleware({ prev: null, parent: null, key: -1 })"
            @dropAction="(data) => dropMiddleware({ prev: null, parent: null, key: -1 }, data)"
          /> 
        </div>
      </div>
      <li v-for="(item, key) in middlewareList" :key=" item ? `${item.identifier}-${versionKey}` : getID(Math.random())">
        <div class="position-relative" :class="[getReorderingMiddlewares.selectedIDs.includes(item.id) ? 'force-disable' : '' , item.is_active == false ? 'middleware-disabled' : ''  ]">
          <div v-if="item.loading || (timedRendering && timedRendering < key) ">
            <middleware-card-skeleton 
              :arrowType="arrowType((middlewareList.length - 1 == key), key)"
              :arrowWidth="arrowWidth[arrowType((middlewareList.length - 1 == key), key)]"
              :hasChildren="item.hasChildren"
            />
          </div>
          <template v-else>
            <div class="nested-middlewares-line" v-if="item.enum_type.has_child" :class=" !rearrangementOverlayOff ? 'overlay-line': ''" />
            <middleware-spot
              :ref="getID(`middleware-${item.identifier}`)"
              :id="parseInt(item.id)"
              :index="key"
              v-model="middlewareList[key]"
              :middlewareIcon="getMiddlewareIcon(item.enum_type.id)"
              :no_agents="hasAgents(item.enum_type.id)"
              :previous="getPreviousMiddleware(key)"
              :next="getNextMiddleware(key)"
              :category="category"
              :direction="direction"
              :areMiddlewaresShown="middlewaresShown.includes(item.id)"
              :depth="depth"
              :parent_id="item.id"
              
              @middlewaresShown="toggleMiddlewaresShown"
              @middlewaresShownChanged="$emit('middlewaresShownChanged')"
              @adjustMinimap="$emit('adjustMinimap')"
              @deletedMiddlewares="(data) =>{$emit('deletedMiddlewares', data)}"
              @selectMiddleware="selectMiddleware"
              @agentEditted="updateAgentEditted"
              @deleteMiddleware="(payload) => { confirmDeleteMiddleware(payload, key) }"
              @refreshMiddlewares="$emit('refreshMiddlewares')"
              @toggleMiddlewareActive="(e) => toggleMiddlewareActive(key , e)"
            >
              <template #arrow="{ type }">
                <div :class="isThisBeforeCroppedMid(key)? 'force-disable': ''">
                  <svg-arrow
                  :key="`arrow-${versionKey}`"
                  class="mb-25 zoom-it"
                  :direction="arrowType((middlewareList.length - 1 == key), key)"
                  scale="0.5" 
                  :width="arrowWidth[arrowType((middlewareList.length - 1 == key), key)]"
                  height="70"
                  event="addMiddleware" 
                  
                  @addMiddleware="chooseMiddleware({ prev: getPreviousMiddlewareID(key), parent: getParentMiddlewareId(key), key })"
                  @dropAction="(data) => dropMiddleware({ prev: getPreviousMiddlewareID(key), parent: getParentMiddlewareId(key), key }, data)"
                  />  
                </div>
              </template>
            </middleware-spot>
          </template>
        </div>
      </li>
      <b-card v-if="root" style="width: 450px" class="text-center mb-5 zoom-it">
        <strong class="text-primary">
          END
        </strong>
      </b-card>
    </ul>
    <div v-else class="middleware-container ml-4 zoom-it">
      <b-skeleton class="mb-2 middleware-card" animation="wave" height="134.59px"/>
      <b-skeleton class="mb-2 middleware-card" animation="wave" height="134.59px"/>
      <b-skeleton class="mb-2 middleware-card" animation="wave" height="134.59px"/>
    </div>
  </div>

  <b-modal
    :id="getID('choose-middleware')"
    @close="setMiddlewareAddInfo"
    ok-only
    no-close-on-backdrop
    lazy
    centered
    :title="$t('middleware.modal.select_middleware.title')"
    ref="select-middleware-modal"
  >
    <validation-observer ref="createMiddleware">
      <validation-provider
        #default="{ errors }"
        name="middleware name"
        rules="required"
      >
        <label for="middleware-name">Middleware Name</label>
        <b-input-group>
          <b-form-input
            id="middleware-name"
            type="text"
            ref="middleware-name-field"
            v-model="middlewareName"
            autofocus
            @focus="$refs['middleware-name-field'].select()"
            @keydown.enter="validateMiddlewareAdd()"
          />
          <b-input-group-append
            is-text
            class="cursor-pointer"
            @click="generateMiddlewareName"
          >
            <feather-icon
              :class="iconRefreshSpinning ? 'text-info spin-refresh': 'text-info'"
              icon="RefreshCwIcon"
            />
          </b-input-group-append>
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </validation-observer>
    
    <v-select
      class="mt-1"
      v-if="middlewareEnumList"
      v-model="middlewareItem"
      :options="middlewareEnumList"
      :clearable="false"
      :reduce="(item)=>item"
      :getOptionLabel="(item) => $t(`middleware.list.${item.i18key}.name`)"
      @input="focusConfirm()"
    >
    
      <template #option="item">
        <b-badge class="mr-50" :class="{border: middlewareItem.id == item.id}" :variant="middlewareItem.id == item.id ? 'success' : 'light-primary' ">
          <b-icon :icon="item.icon" :variant="middlewareItem.id == item.id ? 'black' : 'light'" scale="1.15"/> 
        </b-badge>

        <span :class="{'text-black font-weight-bold': middlewareItem.id == item.id }">{{ $t(`middleware.list.${item.i18key}.name`) }}</span>
      </template>

      <template #selected-option="item">
        <b-badge class="mr-50" variant="light-primary">
          <b-icon :icon="item.icon" variant="light" scale="1.15"/> 
        </b-badge>

        <span>{{ $t(`middleware.list.${item.i18key}.name`) }}</span>
      </template>
      
    </v-select>

    <template #modal-footer>
      <div class="m-0 p-0">
        <b-button variant="success" @click="validateMiddlewareAdd" ref="confirm-button">
          <span class="text-black">{{$t('middleware.modal.select_middleware.ok')}}</span>
        </b-button>
      </div>
    </template>
  </b-modal>

</div>
</template>

<script>
import {
  BContainer,
  BRow,
  BCol,
  BCard,
  BButton,
  BFormGroup,
  BSpinner,
  BSkeleton,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BFormCheckbox,
  BOverlay,
  BBadge,
} from "bootstrap-vue";
import Direction from '@/custom/class/Middleware/Direction';
import HelperTooltip from '@/layouts/components/HelperTooltip'
import Helper from '@/layouts/components/Helper.vue'
import Middleware from './class/Middleware.js'
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "@validations";
import SvgArrow from '@/layouts/components/SvgArrow.vue'
import EnumMiddlewares from '@/custom/class/Enum/Middlewares';
import Category from "@/custom/class/Middleware/Category";
import ConnectorLine from './Components/ConnectorLine.vue';
import { v4 as uuidv4 } from 'uuid'
import MiddlewareSpot from './MiddlewareSpot.vue'
import VSelect from 'vue-select'
import { makeToast } from "@/layouts/components/Popups"
import DefaultAgent from "@/layouts/components/Transmission/Middleware/Agent/DefaultAgent";
import MiddlewareOrigin from '@/custom/class/Enum/MiddlewareOrigin';
import BodyTypes from '@/custom/class/Enum/BodyTypes.js'
import Method from '@/custom/class/Integrator/Method'
import MiddlewareCardSkeleton from '@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareCardSkeleton.vue'
import Middlewares from "@/custom/class/Enum/Middlewares.js"

import { mapGetters } from "vuex";
import { successToast } from '@/custom/class/FunctionClasses/CommonToasts.js';

  export default {
    components: {
      BContainer,
      BRow,
      BCol,
      BCard,
      BButton,
      BFormGroup,
      BSpinner,
      BSkeleton,
      BInputGroup,
      BFormInput,
      BInputGroupAppend,
      ValidationObserver, 
      ValidationProvider, 
      SvgArrow,
      HelperTooltip,
      Helper,
      ConnectorLine,
      MiddlewareSpot,
      VSelect,
      BFormCheckbox,
      MiddlewareCardSkeleton,
      BBadge,
    },  
    props: {
      direction: {
        type: Number,
        required: true,
      },  
      value: {
        type: Array,
        default: () =>[],
      },
      depth: {
        type: Number,
        default: 0,
      },
      category: {
        type: Category,
        required: true,
      },
      root: {
        type: Boolean,
        default: false,
      },
      isShown: {
        type: Boolean,
        default: false,
      },
      parent_id: {
        type: Number,
        default: null,
      },
      
    },
    data() {
      return {
        required,
        agentItem: null,
        middlewareItem: null,
        middlewareName: null,
        uuidMap: {},
        versionKey: uuidv4(),
        arrowWidth: {
          'right-down': 520,
          'left-down': 330,
          'down': 460
        },
        middlewaresShown: [],
        middlewareAddPrevious: null,
        middlewareAddParent: null,
        middlewareAddKey: null,
        agentList: new Array(),
        iconRefreshSpinning: false,
        deleteModalInstance: undefined,
        loadingArrows: [],
        timedRendering: 1,
        initiallySavedAsOpenedMiddlewares: undefined
      }
    },
    computed: {
      middlewareList: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value) 
          
        }
      },
      ...mapGetters(["rearrangementOverlayOff", "getSelectedMiddlewares", 'getCopiedMiddlewares', 'getReorderingMiddlewares']),
      ...mapGetters('internal', ['getFluxPressedKeys']),
      allowMiddlewareEditing(){
        return this.rearrangementOverlayOff
      },
      transmissionID() {
        return this.$route.params.transmissionID;
      },
      middlewareEnumList() {
        const middlewares = new EnumMiddlewares();
        
        return middlewares.items.reduce((previous, el) => {
          if(el.active) {
            previous.push(el);
          }
          return previous;
        }, []);
      },
      firstMiddleware() {
        return this.middlewareList[0] ? this.middlewareList[0].id : null
      },
    },
    mounted() {
      this.init();
      this.renderTimer();
    },
    methods: {
      init() {
        this.middlewareItem = this.middlewareEnumList[0];
        this.generateMiddlewareName();
      },
      updateAgentList(agentList, identifier) {
        this.$refs[this.getID(`middleware-${identifier}`)][0].updateAgentList(agentList, 'try')
      },
      reactiveList() {
        this.$emit('adjustMinimap');

        this.versionKey = uuidv4();
      },
      chooseMiddleware(payload) {
        if(this.getCopiedMiddlewares){
          
          let previous;
          if (this.middlewareList[payload.key]){
            previous = this.middlewareList[payload.key].id
          } else {
            previous = null
          }          
          this.addMiddlewaresBulk(this.getCopiedMiddlewares, previous)
          return

        } else if(this.getReorderingMiddlewares.data.length > 0){

          let previous;
          if (this.middlewareList[payload.key]){
            previous = this.middlewareList[payload.key].id
          } else {
            previous = null
          }          

          // if(this.middlewareList[payload.key])
          
          this.reorderMiddlewares(this.getReorderingMiddlewares.data, previous)
          return
        } 
        this.setMiddlewareAddInfo(payload.prev, payload.parent, payload.key);
        this.generateMiddlewareName();
        this.$bvModal.show(this.getID("choose-middleware"));
      },
      dropMiddleware({ prev, parent, key }, { item, service, originID,}) {
        this.setMiddlewareAddInfo(prev, parent, key);

        switch (originID) {
          case 1: { //* Internal
            this.generateMiddlewareName();
            this.middlewareItem = item;
            this.$nextTick(() => {
              this.addMiddleware()
            })
          } break;
          case 2: { //* Integrator
            let bodyType = item.body.sent.type
            
            this.middlewareName = item.name
            this.middlewareItem = this.middlewareTypeByRequestType(bodyType.id || bodyType)
            
            this.$nextTick(() => {
              this.addMiddleware(true).then(({ resp, addedAt }) => {
                const middlewareID = resp[0].id
                var integratorData = new Object()
                this.buildMetaData({ ...item, ...service }, middlewareID, originID)
                integratorData = this.normalizeItemToAgent(item, integratorData)
                integratorData.uri = `${service.host}${integratorData.uri}`

                this.buildAgents(integratorData, middlewareID, originID)
                this.$nextTick(()=>{
                  this.saveAgents(addedAt, middlewareID).then(() => {
                    this.$nextTick(()=>{
                      this.reactiveList()
                      // this.$refs[this.getID(`middleware-${resp[0].identifier}`)][0].fetchMiddlewareByID()
                    })
                  }).catch((err)=>{
                    console.error(err)
                  });
                })
              }).catch((error) => {
                console.error(error)
              })
            })

          } break;
        }
      },
      normalizeItemToAgent(item, intData) {
        intData = {
          method: undefined,
          uri: undefined,
          path: undefined,
          body: undefined,
          headers: undefined,
          queries: undefined,
        }

        intData.method = new Method(item.enum_metodo.id || item.enum_metodo).script_output
        intData.uri = item.endpoint

        intData.headers = new Array()
        if (item.headers.sent) {
          let headerSent = item.headers.sent
          Object.keys(headerSent).forEach((key) => {
            if (!key || !headerSent[key].example) return

            intData.headers.push({ key, value: headerSent[key].example })
          })
        }

        intData.queries = new Array()
        if (item.queries.sent) {
          let querieSent = item.queries.sent
          Object.keys(querieSent).forEach((key) => {
            if (!key || !querieSent[key].example) return
            
            intData.queries.push({ key, value: querieSent[key].example })
          })
        }

        intData.body = this.returnBodyParsed(item.body.sent.type, item.body.sent)

        return intData
      },
      returnBodyParsed(bodyTypeID, { schema, fields }) {
        let data_string = new Array() 

        switch (bodyTypeID) {
          case 2: {
            data_string = schema
          } break;
          case 3: {
            var xmlString = schema
            // var domParser = new DOMParser();
            // var dom = domParser.parseFromString(xmlString, 'text/xml');

            data_string = schema
          } break;
          case 4: {
            data_string = this.setRequestBodyList(fields)
          } break;
          case 5: {
            data_string = this.setRequestBodyList(fields)
          } break;
          case 6: {
            data_string = schema
          } break;
        }

        return data_string
      },
      setRequestBodyList(body) {
        let bodyArr = new Array()
        Object.keys(body).forEach((key) => {
          if (!key || !body[key].example) return

          bodyArr.push({ key, value: body[key].data_type.id == 2 ? parseInt(body[key].example) : body[key].example.toString() })
        })

        return bodyArr
      },
      middlewareOriginAgents(originID, field = null) {
        if (field) {
          return new MiddlewareOrigin().items.find(el => el.id == originID).agents[field]
        }
        return new MiddlewareOrigin().items.find(el => el.id == originID).agents
      },
      buildAgents(integratorData, middlewareID, originID) {  
        Object.keys(integratorData).forEach((key) => {
          let originInfo = this.middlewareOriginAgents(originID, key)
          
          switch (true) {
            case (integratorData[key] instanceof Array): {
              if (integratorData[key].length) {
                integratorData[key].forEach((item) => {

                  let keySourceMap = {
                    headers: 'HEADER.',
                    queries: 'QUERY.',
                    path: 'PATH.',
                    body: 'BODY.',
                  }
                  
                  this.agentList.push(
                    {
                      agent_id: null,
                      middleware: middlewareID,
                      enum_agent_action: originInfo.default_action,
                      enum_agent_block_id: originInfo.default_block,
                      enum_source_destiny_id: originInfo.register_destiny?.source,
                      destiny_value: `${keySourceMap[key]}${item.key}`,
                      enum_source_register_1: originInfo.register_1?.source || 7,
                      register_1_value: item.value,
                      enum_source_register_2:  originInfo.register_2?.source || null,
                      register_2_value:  originInfo.register_2?.value || null,
                      fatal_on_fail: true,
                    }
                  );
                });
              }
            } break;
            default: {

              if (integratorData[key]) {
                this.agentList.push(
                  {
                    agent_id: null,
                    middleware: middlewareID,
                    enum_agent_action: originInfo.default_action,
                    enum_agent_block_id: originInfo.default_block,
                    enum_source_destiny_id: originInfo.register_destiny?.source,
                    destiny_value: originInfo.register_destiny?.value,
                    enum_source_register_1: originInfo.register_1?.source || 7,
                    register_1_value: integratorData[key],
                    enum_source_register_2:  originInfo.register_2?.source || null,
                    register_2_value:  originInfo.register_2?.value || null,
                    fatal_on_fail: true,
                  }
                  );
                }
              }
            }
          })
      },
      saveAgents(addedAt , middlewareID) {
        return new Promise((resolve, reject) => {
          // this.agentList[3].register_1_value = null
          this.$store
          .dispatch("saveAgents", {
            agentList: this.agentList,
            transmissionID: this.$route.params.transmissionID,
          })
          .then((response) => {

            this.$emit("saved", response.data);

            let idx = this.middlewareList.findIndex((el)=> el.id == middlewareID)

            if (idx > -1){
              this.middlewareList[idx].agents.try = structuredClone(response.data)
            } else {
              let outerIdx = -1
              let r = -1
              this.middlewareList.forEach(mid => {
                outerIdx = outerIdx + 1
                let foundIdx = mid.middlewares.findIndex((el)=> el.id == middlewareID)
                if (foundIdx > -1){
                  r = [outerIdx , foundIdx]
                }
              });
              this.middlewareList[r[0]].middlewares[r[1]].agents.try = structuredClone(response.data)
            }

            this.isSaving = false
            this.agentList = []
            makeToast({
              title: this.$t("agent.toast.create_agents.success.title"),
              text: this.$t("agent.toast.create_agents.success.message"),
              variant: "success",
              icon: "CheckIcon",
            });

            resolve(true)

          })
          .catch((error) => {
            console.error(error);
            this.isSaving = true
            reject(error)

          });
        })


      },
      buildMetaData({ avatar, docs, endpoint, host, enum_metodo, name, id  }, middlewareID, originID) {
        let metaData = {
          id,
          avatar,
          url: `${host}${endpoint}`,
          docs,
          enum_metodo,
          name,
          origin: new MiddlewareOrigin().items.find(el => el.id == originID)
        }

        this.agentList.push(
          {
            agent_id: null,
            middleware: middlewareID,
            enum_agent_action: 0,
            enum_agent_block_id: 1,
            enum_source_destiny_id: null,
            destiny_value: null,
            enum_source_register_1: 7,
            register_1_value: JSON.stringify(metaData),
            enum_source_register_2: null,
            register_2_value: null,
            fatal_on_fail: false,
          }
        );
      },
      middlewareTypeByRequestType(typeID) {
        let types = {
          1: 2,  //* None
          2: 2,   //* JSON
          3: 9,   //* XML
          4: 31,  //* Form Data
          5: 30,  //* URL Encoded
          6: 10   //* Raw
        }

        return new EnumMiddlewares().items.find(el => el.id == types[typeID])
      },
      validateMiddlewareAdd() {
        this.$refs.createMiddleware.validate().then((success) => {
          if (success) {
            this.addMiddleware()
            this.$refs['select-middleware-modal'].hide()
          } else {
              
          }
        })
      },
      addMiddlewaresBulk(middlewares, previous){
        let payloadList = []
        function treatAgents(agents){
          let r = {
            try: [],
            then: [],
            catch: [],
          }
          Object.keys(r).forEach(mod => {
            if (agents[mod]){
              agents[mod].forEach(el => {
                let agent = el
                agent.action = agent.enum_agent_action.id
                if (agent.register_destiny){
                  agent.destiny = agent.register_destiny
                }
                r[mod].push(agent)
              });
            }
          });
          return r;
        }
        
        function treatMidInfo(mids){
          let r = []
          mids.forEach((mid)=>{
            
            let temp = {
                //associated_id: null,
                name: mid.name,
                //enum_type: mid.enum_type.id,
                is_active: mid.is_active,
                is_fatal: mid.is_fatal,
                type: mid.enum_type.id,
                agents: treatAgents(mid.agents)
              }
            

            if (mid.middlewares && mid.middlewares.length){
              temp.middlewares = treatMidInfo(mid.middlewares)
            }
            r.push(temp)
          })
          return r
        }
        
        payloadList = treatMidInfo(middlewares)
        let payload = {
          list: payloadList,
          previous: previous,
          transmissionID: this.transmissionID,
          service: this.category.service_id,
          event: this.category.event_id,
          enum_direction: this.category.direction.id
        }       

        //BANANA
        //in the future we should add some form of loading state to the flux here,
        //as well as integrating this with reactivity - right now we can't do it because of the API's response
        
        this.$store.dispatch('bulkAddMiddlewares', payload)
          .then((resp) => {
            this.refreshMiddlewares()
          })
          .catch((err)=>{
            console.error(err)
          })
      },
      reorderMiddlewares(middlewares, previous){
        
        let first = middlewares[0].id
        let last = middlewares[middlewares.length-1].id

        let payload = {
          first: first,
          last: last,
          previous: previous,
          transmissionID: this.transmissionID
        }

        this.$store.dispatch('reorderMiddlewares', payload).then(()=>{
          makeToast({
            title: 'Success!',
            text: 'Middlewares successfully reordered',
            variant: "success",
            icon: "ShuffleIcon",
          });

          let removingMidIDs = []
          middlewares.forEach((mid)=>{
            removingMidIDs.push(mid.id)
          })

          this.$emit('deletedMiddlewares', removingMidIDs) // - MIDDLEWARES IN REORDER REMOVED FROM FLUX

          this.$nextTick(()=>{
            let idx = this.middlewareList.findIndex(el => el.id == previous);

            if (idx > -1 && this.middlewareList[idx] && this.middlewareList[idx].enum_type.has_child){  
              let innerIdx = 0
              middlewares.forEach(el => {
                this.middlewareList[idx].middlewares.splice(innerIdx, 0, el)
                innerIdx = innerIdx + 1
              });
            } else {
              middlewares.forEach(el => {
                //this works even if no Idx was found, (AKA: idx = -1) - properly placing the middlewares first in the chain
                idx = idx + 1
                this.middlewareList.splice(idx, 0, el)
              });
            }
            
          })
          
        })
        .catch((err)=>{
          console.error(err)
          makeToast({
            title: 'Error!',
            text: 'Something went wrong while reordering Middlewares...',
            variant: "danger",
            icon: "XIcon",
          });
        })
      },
      addMiddleware(is_integrator=false) {
        return new Promise((resolve, reject) => {
          let payload = {
            "associated_id": "1",
            "identifier": null,
            "enum_direction": this.direction,
            "enum_type": this.middlewareItem.id,
            "event": parseInt(this.category.event_id) || null,
            "service": parseInt(this.category.service_id) || null,
            "name": this.middlewareName,
            "is_fatal": false,
            "transmissionID": this.transmissionID,
            "previous_id": this.middlewareAddPrevious,
            "parent_id": this.middlewareAddPrevious,
          }
          // this.$store.dispatch('setLoadingState', true);
          setTimeout(() => {
            this.generateMiddlewareName()
          }, 200);
          
          
          let index = this.middlewareAddKey;
          let downADeapth = undefined
          
          let loadingMiddlewareData = {
            loading: true,
            hasChildren: this.middlewareItem.hasChildren,
          }

          if (this.middlewareList[index] && this.middlewareAddParent == this.middlewareList[index].id) {
            this.middlewareList[index].middlewares.unshift(loadingMiddlewareData)
            downADeapth = true
          } else {
            this.middlewareList.splice(index+1, 0, loadingMiddlewareData);
            downADeapth = false
          }

          this.$store.dispatch('createMiddleware', payload).then(async (response) => {

            if (downADeapth){
              this.middlewareList[index].middlewares.shift()
              //removes loading state middleware from middlewareList
            } else {
              this.middlewareList.splice(index+1, 1)
            }
           
            //* always the first child
            if (this.middlewareList[index] && this.middlewareAddParent == this.middlewareList[index].id) {
              //* reverse for unshift
              response.reverse().forEach((value) => {
                //* Sets the first middleware of the middlewares list previous_id to the new inserted middleware, then unshift it in the Array
                if(this.middlewareList[index].middlewares[0]) {
                  this.middlewareList[index].middlewares[0].previous_id = value.id;
                }
                value.middlewares = [];
                this.middlewareList[index].middlewares.unshift(value)
              })

              //* The index of the last inserted middleware is setted as the next_id of the parent middleware
              this.middlewareList[index].next_id = this.middlewareList[index].middlewares[0].id;
            } else {
              //* if the insert is at the same level os the current list it only inserts it in the selected position
              response.forEach((value, key) => {
                if(this.middlewareList[index + key]) {
                  this.middlewareList[index + key].next_id = value.id;
                }
                value.middlewares =  [];
                this.middlewareList.splice(index + key + 1, 0, value);
              })
            }            
            this.$store.dispatch('setLoadingState', false);

            this.$nextTick(() => {
              if (this.$refs[this.getID(`middleware-${this.middlewareList[index + 1].identifier}`)][0]) {
                if (!is_integrator){
                  this.$refs[this.getID(`middleware-${this.middlewareList[index + 1].identifier}`)][0].toggleMiddlewareSidebar(true);
                }
              }

              if (downADeapth && this.middlewareList[index] && !this.middlewaresShown.includes(this.middlewareList[index].id)){
                this.$nextTick(()=>{
                  this.$refs[ this.getID(`middleware-${this.middlewareList[index].identifier}`)][0].toggleMiddlewares(false)
                  this.toggleMiddlewaresShown(index)
                })
              }
            })

            resolve({ resp: response, addedAt: index + 1 })
          }).catch((error) => {
            this.$store.dispatch('setLoadingState', false);
            reject(error)
          }).finally(()=>{})
        })
      },
      confirmDeleteMiddleware(payload, index) {
        if (this.deleteModalInstance) return
        this.deleteModalInstance = this.$bvModal
        .msgBoxConfirm(
            this.$t("middleware.modal.delete.message", {
              name: this.$t(payload.middleware.name),
            }),
            {
              title: this.$t("middleware.modal.delete.title"),
              size: "sm",
              okVariant: "danger",
              okTitle: this.$t("common.terms.yes"),
              cancelTitle: this.$t("common.terms.no"),
              cancelVariant: "outline-secondary",
              hideHeaderClose: false,
              centered: true,
              autoFocusButton: "ok",
              
            }
          )
          .then((value) => {
            this.deleteModalInstance = undefined;
            if (value) {
              this.$store.dispatch('deleteMiddleware', { id: payload.middleware.id, transmissionID: this.transmissionID})
                .then(() => {
                  if (index == this.middlewareList.length - 1) {
                    this.$emit("lastMiddlewareDeleted", this.middlewareList[index]);
                  }

                  let lastDeleted = this.middlewareList[index];
                  if (payload.type.has_child) {
                    if (this.middlewareList[index - 1]) {
                      this.middlewareList[index - 1].next_id = this.middlewareList[index + 1].next_id;
                    }

                    if (this.middlewareList[index + 2]) {
                      this.middlewareList[index + 2].previous_id = this.middlewareList[index].previous_id;
                    }

                    lastDeleted = this.middlewareList[index + 1];
                    this.middlewareList.splice(index, 2);
                  } else {
                    if (this.middlewareList[index - 1]) {
                      this.middlewareList[index - 1].next_id = this.middlewareList[index].next_id;
                    }

                    if (this.middlewareList[index + 1]) {
                      this.middlewareList[index + 1].previous_id = this.middlewareList[index].previous_id;
                    }

                    this.middlewareList.splice(index, 1);
                  }

                  if (index == 0) {
                    this.$emit("firstMiddlewareDeleted", lastDeleted);
                  }

                  // this.reactiveList();

                  makeToast({
                    title: this.$t("middleware.toast.delete.success.title"),
                    text: this.$t("middleware.toast.delete.success.message"),
                    variant: "success",
                    icon: "CheckIcon",
                  });
                })
                .catch(() => {
                  makeToast({
                    title: this.$t("middleware.toast.delete.error.title"),
                    text: this.$t("middleware.toast.delete.error.message"),
                    variant: "danger",
                    icon: "XIcon",
                  });
                });
            }
          });
      },
      getNextMiddleware(key) {
        if(!this.middlewareList[key+1]) {
          return undefined;
        }

        return new Middleware({
          ...this.middlewareList[key+1]
        });
      },
      getPreviousMiddleware(key) {
        if(!this.middlewareList[key-1]) {
          return undefined;
        }
        return new Middleware({
          ...this.middlewareList[key-1]
        });
      },
      getMiddlewareIcon(id) {
        let icon = null;
        let midEnum = new EnumMiddlewares().items
        midEnum.forEach((item) => {
          if (item.icon && item.id == id) {
            icon = item.icon;
          }
        });
        return icon;
      },
      hasAgents(id) {
        let no_agents = false;
        this.middlewareEnumList.forEach((item) => {
          if (item.no_agents && item.id == id) {
            no_agents = item.no_agents;
          }
        });
        return no_agents;
      },
      getID(key) {
        if (this.uuidMap[key]) {
          return this.uuidMap[key];
        }

        const uuid = uuidv4();
        this.uuidMap[key] = uuid;

        return uuid;
      },
      setMiddlewareAddInfo(prev = null, parent = null, key) {
        this.middlewareAddPrevious = prev;
        this.middlewareAddParent = parent;
        this.middlewareAddKey = key;
      },
      generateMiddlewareName() {
        this.$store.dispatch('customNameGenerator').then((response) => {
          this.middlewareName = response;
        })
        this.iconRefreshSpinning = true;
        setTimeout(() => {
          this.iconRefreshSpinning = false;
        }, 700);
      },
      getLocalStorageOpenedMiddlewares(){
        let local = localStorage.getItem('openedMiddlewares')
        let transmission = String(this.transmissionID)
        if (local){
          try{
            local = JSON.parse(local)            
            if (local[transmission]){
              this.initiallySavedAsOpenedMiddlewares = local[transmission]
            } else {
              this.initiallySavedAsOpenedMiddlewares = []
            }
          }
          catch(err){
            console.error(err)
            localStorage.removeItem('openedMiddlewares');
          }
        } else {
          this.initiallySavedAsOpenedMiddlewares = []
        }
      },
      toggleMiddlewaresShown(key) {
        let midId = this.middlewareList[key].id;

        if (this.middlewaresShown.includes(midId)){
          let idx = this.middlewaresShown.findIndex(el => el == midId)
          this.middlewaresShown.splice(idx, 1)
        } else {
          this.middlewaresShown.push(midId)
        }
        
        // this.middlewaresShown[key] = !this.middlewaresShown[key];
        this.$emit('middlewaresShownChanged');

        let status =  this.middlewaresShown.includes(midId);
        let transmission = String(this.transmissionID)
        try {
          let local = localStorage.getItem('openedMiddlewares')
          if (local){
            local = JSON.parse(local);
          } else {
            local = {}
          }
          if (!local[transmission]){
            local[transmission] = []
          }

          if (status){
            local[transmission].push(midId)
          } else {
            let idx = local[transmission].findIndex(el => el == midId)
            if (idx > -1){
              local[transmission].splice(idx, 1);
            }
          }
          localStorage.setItem('openedMiddlewares', JSON.stringify(local));
        }
        catch(err){
          console.erro(err)
          localStorage.removeItem('openedMiddlewares');
        }
      },
      toggleIsShown() {
        this.isShown = !this.isShown;
      },
      arrowType(isLast, key) {
        let isOpen = this.middlewaresShown.includes(this.middlewareList[key].id)
        switch (true) {
          case (isLast && this.depth && this.isShown): {
            return 'left-down';
          }
          case isOpen && !isLast && (this.middlewareList[key].middlewares && this.middlewareList[key]?.middlewares.length > 0): {
            return 'right-down';
          }
          default: {
            return 'down';
          }
        }
      },
      refreshMiddlewares() {
        this.$emit('refreshMiddlewares');
      },
      focusConfirm(){
        this.$nextTick(()=>{
          this.$refs['confirm-button'].focus()
        })
      },
      treatPreviousAndNextID(item){
        let rData = item.data

        if (!rData){
          return item
        }
        let list = structuredClone(this.middlewareList)
        
        let idx = list.findIndex(el => el.id == rData.id)

        if (idx > -1){
          if (list[idx+1]){
            rData.next_id =  list[idx+1].id
          } else {
            rData.next_id = null 
          }

          if (list[idx-1]){
            rData.previous_id =  list[idx-1].id
          } else {
            rData.previous_id = null 
          }
        }
        
        return {...item, data: rData}
      },
      selectMiddleware(item, canPropagate = true){
        item = this.treatPreviousAndNextID(item)

        const parentCheck = item.data.parent_id == this.parent_id && this.getSelectedMiddlewares.data.length > 0 && item.data.parent_id == this.getSelectedMiddlewares.data[0].parent_id
        
        if (this.getFluxPressedKeys.includes('shift') && canPropagate && parentCheck ){
          const itemIdx = this.middlewareList.findIndex(el => el.id == item.data.id)
          
          const first = this.getSelectedMiddlewares.data[0]
          const firstIdx = this.middlewareList.findIndex(el => el.id == first.id)

          const last = this.getSelectedMiddlewares.data[this.getSelectedMiddlewares.data.length -1]
          const lastIdx = this.middlewareList.findIndex(el => el.id == last.id)
          // let goal = item.data.id

          let closestIdx = [firstIdx , lastIdx].reduce(function(prev, curr) {
            return (Math.abs(curr - itemIdx) < Math.abs(prev - itemIdx) ? curr : prev);
          });


          if ( Math.abs( closestIdx - itemIdx ) > 1){ // normally would be too far to select
            let modifier;
            if (closestIdx < itemIdx){
              modifier = +1
            } else {
              modifier = -1
            }

            
            let nextIdx = structuredClone(closestIdx)
            while (true){
              nextIdx = nextIdx + modifier


              let skipThisMiddleware = false
              /* skipThisMiddleware use cases:
                  * selecting Middlewares for top to bottom -> skip End Block
                  * selecting Middlewares for bottom to top -> skip Start Block (If, For, ForEach...)
              */
              if (modifier > 0){
                const endBlockMiddlewares = new Middlewares().getEndblocks()
                skipThisMiddleware = endBlockMiddlewares.findIndex(el=>el.id == this.middlewareList[nextIdx].enum_type.id) > -1
              } else {
                const startBlockMiddlewares = new Middlewares().getStartBlocks()
                skipThisMiddleware = startBlockMiddlewares.findIndex(el=>el.id == this.middlewareList[nextIdx].enum_type.id) > -1
              }


              if ( !skipThisMiddleware ){  
                let r = structuredClone(item)
                r.data = this.middlewareList[nextIdx]
                this.selectMiddleware(r, false)
              }
              if (nextIdx == itemIdx){
                break
              } 
            }
            return
          }
        }

        
        let idx = this.middlewareList.indexOf(item.data)
        let oldSelectLength = structuredClone(this.getSelectedMiddlewares.data.length)
        
        if (idx > -1){      
          if (item.data.enum_type.end_block){
            let endBlock = {
              depth: item.depth,
              data: this.treatPreviousAndNextID(this.treatPreviousAndNextID(this.middlewareList[idx+1])),
              exception: 'next',
            }
            this.$emit('selectMiddleware' , item)
            if (this.getSelectedMiddlewares.data.length != oldSelectLength){
              this.$emit('selectMiddleware' , endBlock)
            }
          } else if (this.middlewareList[idx-1]?.enum_type.end_block?.type){
            let startBlock = {
              depth: item.depth,
              data: this.middlewareList[idx-1],
              exception: 'previous',
            }
            this.$emit('selectMiddleware' , item)  
            if (this.getSelectedMiddlewares.data.length != oldSelectLength){
              this.$emit('selectMiddleware' , startBlock)
            }
          } else {
            this.$emit('selectMiddleware' , item) 
          }
        } else {
          this.$emit('selectMiddleware' , item) 
        }
      },
      toggleMiddlewareActive(idx, status){
        this.middlewareList[idx].is_active = undefined
        if (this.middlewareList[idx].enum_type.has_child){
          this.middlewareList[idx + 1].is_active = undefined
        }
        
        const payload = {
          transmissionID: this.transmissionID,
          middlewareID: this.middlewareList[idx].id,
          value: status,
        }
        this.$store.dispatch('pathMiddlewareActive',payload)
        .then((resp)=>{
          
          this.middlewareList[idx].is_active = status
          if (this.middlewareList[idx].enum_type.has_child){ // also changes is_active on End Blocks
            this.middlewareList[idx + 1].is_active = status
          }
        })
        .catch((err)=>{
          console.error(err)
          this.middlewareList[idx].is_active = !status
        })
      },
      removeFromMidList(IDs){
        let newIDs = structuredClone(IDs)

        IDs.forEach(id => {
          let idx = this.middlewareList.findIndex(el => el.id == id);
          
          if (idx > -1){
            //removes from middlewareList and emmited IDs
            newIDs.splice(newIDs.indexOf(id), 1)
            this.middlewareList.splice(idx, 1)
            if (idx == 0){
              // this.middlewaresShown[0] = true
            }
          } 
        });

        if (newIDs.length){
          this.middlewareList.forEach((mid)=>{
            if (mid.enum_type.has_child && !!(this.$refs[this.getID(`middleware-${mid.identifier}`)][0])){
              this.$refs[this.getID(`middleware-${mid.identifier}`)][0].removeFromMidList(newIDs)
            }
          })
        }
      },
      isThisBeforeCroppedMid(key){
        let cond1 = this.getReorderingMiddlewares.data[0] && this.middlewareList[key+1]

        let cond2 = this.middlewareList[key].middlewares.length > 0

        if (cond1 && this.middlewareList[key+1].id == this.getReorderingMiddlewares.data[0].id){
          return true
        }
         else if (cond1 && cond2 && this.middlewareList[key].middlewares[0].id == this.getReorderingMiddlewares.data[0].id){
          return true
        }
        else{
          return false
        }
      },
      getPreviousMiddlewareID(idx){
        if (this.middlewareList[idx]){
          return this.middlewareList[idx].id
        } else {
          return this.parent_id
        }
      },
      getParentMiddlewareId(key){
        if (this.middlewareList[key].enum_type.has_child){
          return this.middlewareList[key].id
        } else {
          return this.parent_id
        }
      },
      updateAgentEditted(item){        
        let idx = this.middlewareList.indexOf(el=>el.d == item.id)
      },
      renderTimer(){
        if (!this.initiallySavedAsOpenedMiddlewares){
          this.getLocalStorageOpenedMiddlewares()
        }

        let idx = structuredClone(this.timedRendering) - 1;

        this.$nextTick(()=>{
          //timeRendering flag starts at 1 and it renders one index behind
          //  ex: if (timedRending == 2) middlewares of index 0, 1 and 2 are rendered

          let mid = this.middlewareList[idx]
          if (mid && this.initiallySavedAsOpenedMiddlewares.includes(mid.id)){
            this.$nextTick(()=>{
              this.$refs[ this.getID(`middleware-${mid.identifier}`)][0].toggleMiddlewares(false)
            })
            this.middlewaresShown.push(mid.id)
          }
        })

        setTimeout(() => {
          this.timedRendering = this.timedRendering + 1
          
          if (!(this.timedRendering >= this.middlewareList.length)){
            this.renderTimer()
          } else {
            this.timedRendering = null;
          }
        }, 50);
      }
    }
  }
</script>


<style lang="scss">
  @import '@/assets/scss/custom-utils.scss';

//this targets elements in MiddlewareCard.vue
.middleware-disabled{
  $disabled-card-color: #1b2234;

  .middleware-card{
    opacity: all 0.2s;
    opacity: 1;
    border-left: 7px solid transparentize($light, 0.8) !important;
    outline: 1px solid transparentize( $secondary, 0.8);
    background-color: $disabled-card-color;
    &.is-editable:hover{
      background-color: lighten($disabled-card-color, 3) !important;
    }
    > *{
      opacity: 0.5;
    }
  }
  .show-middleware-tab{
    border-color: $disabled-card-color;
    &:hover{
      background-color: $disabled-card-color!important;
    }
  }
  

}

</style>

<style lang="scss" scoped>

.force-disable{
  opacity: 0.5;
  pointer-events: none !important;
  *{
    pointer-events: none !important;
  }
}
li {
  list-style: none;
}

.show-middleware-tab:hover {
  background-color: #283046;
  color: #fff;
}

.show-middleware-tab {
  margin-top: -4px;
  width: 150px;
  font-size: 12px;
  color: #69738f;
  border: solid 3px #283046;
  border-radius: 0px 0px 5px 5px;
  padding: 5px 10px 0px 10px;
  border-top: none;
  z-index: 1;
  transition: all 0.3s ease;
}

@keyframes spin-refresh{
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
}

.spin-refresh{
  animation-name: spin-refresh;
  animation-duration: 0.7s;
}

@keyframes slow-appear{
  0%{
    opacity:0;
  }
  100%{
    opacity: 1;
  }
}

.nested-middlewares-line{
  position: absolute;
  border-left: 1px solid rgba(119, 122, 127, 0.6);
  height: 100%;
  left: 30px;
  top: 15px;
  animation: slow-appear;
  animation-duration: 1s;
  animation-iteration-count: 1;
  &.overlay-line{
    border-left: 3px solid rgba(119, 122, 127, 0.6);
  }
}

</style>