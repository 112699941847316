<template>
<div>

  <b-modal
    ref="manage-template-modal"
    centered
    hide-footer
    size="lg"
    @hide="(e)=>{ if(loading) e.preventDefault()}"
  >
    <template #modal-title>
      <template v-if="!isEdit">
        <b-icon icon="plus-circle" class="mr-25"/>
        Create Middleware <b>Template</b>
      </template>
      <template v-else>
        <b-icon icon="pencil" class="mr-25"/>
        Edit Middleware <b>Template</b>
      </template>
    </template>
  
    <div v-if="isEdit" align="right">
      <b-button variant="outline-danger" class="py-25" @click="$refs['delete-modal'].show()">
        <b-icon icon="trash"/> Delete Template
      </b-button>
    </div>

    <div class="mb-1">
      <h5 class="label-margin">Template Title</h5>
      <b-form-input placeholder="Name of this Template..." v-model.trim="name" :autofocus="!isEdit" maxlength="70" @keydown.enter="$refs['description-input'].focus()"/>
    </div>
    
    <div class="mb-1">
      <h5 class="label-margin">Description</h5>
      <b-form-textarea
        placeholder="A short description of what the Middlewares in this Template do..."
        min-rows="3"
        max-rows="5"  
        maxlength="300"
        v-model.trim="description"
        ref="description-input"
      />
      <div align="right" class="small text-secondary mx-1">
        {{description.length}} / 300 max. characters
      </div>
    </div>
    
    <div class="mb-1">
      <h5 class="label-margin">Middlewares</h5>

      <template v-if="!loadingFetch">
        <div v-if="parsedMiddlewares" class="position-relative">

          <b-button variant="outline-danger" class="py-50 px-50 m-1 remove-middlewares-btn" @click="middlewaresHash=undefined">
            <b-icon icon="x" scale="1.2"/>
            Clear
          </b-button>

        </div>
        <div class="middlewares-container" >

          <div v-if="parsedMiddlewares">
            <flux-clipboard-middleware-display :middlewares="parsedMiddlewares" class="middlewares-displayed"/>
          </div>
          
          <div v-else class="middleware-hash-input">
            <b-button variant="outline-light" size="xl" @click="openImportModal()">
              IMPORT
              <b-icon icon="box-arrow-in-down-left" class="ml-50"/>
            </b-button>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="middlewares-container" >
          <div align="center" class="mt-3">
            <b-spinner class="m-auto" variant="secondary"/>
          </div>
        </div>
      </template>

    </div>

    <div class="mb-1">
      <div>
        <div class="mx-50">Categories</div>
        <div class="mx-50 text-secondary small">
          Pick from 1 to 4 categories that describe this Template (check <span class="font-weight-bold">Private</span> if you don't want others to view this Template)
        </div>
      </div>

      <div class="d-flex flex-wrap align-items-end justify-content-start">
        <b-button variant="none" v-for="cat in allCategories" :key="'category-key-'+cat.id" class="category-badge" :class="{viewing: categories.includes(cat.id)}"  @click="toggleCategory(cat)" :style="`background-color: ${cat.color};`">
          <b-icon :icon="cat.icon" scale="0.8"/>
          {{ cat.label }}
        </b-button>
      </div>
    </div>

    <div class="d-flex align-items-center">
      <hr class="w-100">
      <b-icon icon="square-fill" class="mx-1" scale="0.3" variant="secondary"/>
      <hr class="w-100">
    </div>
    
    <div align="right" class="mx-1">

      <b-button v-if="loading" variant="success" disabled>
        <b-spinner small class="mx-3" variant="black"/>
      </b-button>

      <b-button v-else-if="!isEdit" variant="success" :disabled="!canPublish" :class="{'disabled-low-opacity': !canPublish}" @click="publishTemplate()">
        
        <b-icon icon="check-circle" class="mr-50" variant="black"/>
        <b class="text-black">Publish Template</b>
      </b-button>

      <b-button v-else variant="success" :disabled="!canPublish"  :class="{'disabled-low-opacity': !canPublish}" @click="patchTemplate()">
        <b-icon icon="check-circle" class="mr-50" variant="black"/>
        <b class="text-black">Update Template</b>
      </b-button>

    </div>
    
    <!-- {{ getLoggedUser   }} -->
  </b-modal>


<b-modal
  ref="import-middlewares-modal"
  centered
  size=""
  hide-footer
  body-class="round"
  header-class="rounded"
>
  <template #modal-title>
    <div>
      <h5 class="m-0">
        <feather-icon icon="GitPullRequestIcon" />
        Middleware Import
      </h5>
    </div>
  </template>

  <div class="mb-2">
    <span class="mx-25 font-weight-bold">Import from File:</span>
    <b-form-file accept=".fiqon" v-model="importModal.file" class="">
    </b-form-file>
    <span class="text-secondary small mx-25">
      Ex: Middlewares.fiqon
    </span>
  </div>

  <div align="center">
    <span class="font-weight-bolder">OR</span>
  </div>

  <div>
    <span class="mx-25 font-weight-bold">Import from Text:</span>
    <b-form-textarea v-model.trim="importModal.text"/>
  </div>
  
  <div>

  </div>
  
  <div class="d-flex mt-3">
    <hr class="w-50 mx-1">
    <b-button variant="favorite" class="mx-2 d-flex align-items-center py-50 px-1" :disabled="!(importModal.text || importModal.file)" @click="importMiddlewares()">
      <b-icon icon="box-arrow-in-down-left" class="mr-50" variant="black"/>
      <span class="text-black">Import</span>
    </b-button>
    <hr class="w-50 mx-1">
  </div>
</b-modal>

<b-modal
  ref="delete-modal"
  centered
  ok-only
  ok-variant="danger"
  :ok-disabled="loading"
  @ok="deleteTemplate()"
  @hide="(e) => loading ? e.preventDefault() : ''"
>
  <template #modal-title>
    Confirm delete
  </template>

  <h5 class="my-50 mx-1">
    Delete the Template <b class="text-danger">{{name}}</b>?
  </h5>

  <template #modal-ok>
    <span v-if="!loading">
      Delete Template
    </span>
    <b-spinner v-else class="mx-3" small/>
  </template>

</b-modal>

</div>
</template>

<script>

import {
  BRow,
  BCol,
  BCard,
  BButton,
  BSpinner,
  BContainer,
  BSkeleton,
  BCollapse,
  BBadge,
  BOverlay,
  BFormInput,
  BTooltip,
  VBTooltip,
  VBToggle,
  BFormTextarea,
  BModal,
  BIconstack,
  BFormFile,
} from "bootstrap-vue";
import TemplatesListContent from "@/views/pages/middleware/MiddlewareManagement/TemplatesListContent.vue";
import * as StringCompressor from '@/custom/class/FunctionClasses/StringCompressor.js'
import TemplateCategories from "@/custom/class/Enum/Map/TemplateCategories.js"
import FluxClipboardMiddlewareDisplay from "@/views/pages/middleware/MiddlewareManagement/RearrangementOverlay/FluxClipboardMiddlewareDisplay.vue"
import {mapGetters} from 'vuex'
import { errorToast, successToast } from "@/custom/class/FunctionClasses/CommonToasts";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BSpinner,
    BContainer,
    BSkeleton,
    BCollapse,
    BBadge,
    BOverlay,
    BFormInput,
    BTooltip,
    VBTooltip,
    VBToggle, 
    TemplatesListContent,
    BFormTextarea,
    BModal,
    BIconstack,
    BFormFile,
    FluxClipboardMiddlewareDisplay,
  },
  data() {
    return {
      name: "",
      description: "",
      middlewaresHash: undefined,
      categories: [],
      loading: false,
      loadingFetch: false,
      isEdit: false,
      importModal: {
        text: undefined,
        file: undefined
      }
    }
  },
  computed: {
    ...mapGetters(["getLoggedUser"]),
    parsedMiddlewares() {
      let r = structuredClone(this.middlewaresHash)
      try{
        r = StringCompressor.decompressFromBase64(r)
        r = JSON.parse(r)
      }catch (err){
        r = null
      }      
      return r
    },
    allCategories(){
      return new TemplateCategories().items
    },
    canPublish(){
      return (
        this.name.length > 0 && 
        this.description.length > 0 &&
        this.categories.length > 0 && 
        this.middlewaresHash != undefined
      )
    }
    
  },
  methods: {
    openAddTemplateModal(hashToImport = null){
      // ========= reset modal state ========= 
      this.name = ""
      this.description = ""
      this.middlewaresHash = undefined
      this.helper_id = undefined
      this.categories = []
      this.loading = false
      this.importModal.text = undefined
      this.importModal.file = undefined
      this.isEdit = false
      // ========= reset modal state ========= 

      if (hashToImport != null && hashToImport.length > 0){
        this.middlewaresHash = hashToImport;
      }

      this.$refs['manage-template-modal'].show()
    },
    openEditTemplateModal(template){
      this.importModal.text = undefined
      this.importModal.file = undefined
      this.loading = false
      this.loadingFetch = false
      
      this.isEdit = true
      
      this.helper_id = template.id
      this.name = template.name
      this.description = template.description
      this.categories = template.categories 

      this.middlewaresHash = undefined

      this.$refs['manage-template-modal'].show()
      this.fetchById(template.id)
    },
    fetchById(id){
      this.loadingFetch = true;

      this.$store.dispatch('template/fetchTemplateById', {id: id})
        .then((resp)=>{
          this.middlewaresHash =  resp.middleware_hash
        })
        .finally(()=>{
          this.loadingFetch = false;
        })
    },
    openImportModal(){
      this.importModal.text = undefined
      this.importModal.file = undefined      
      this.$refs['import-middlewares-modal'].show()
    },
    toggleCategory(cat){
      let idx = this.categories.findIndex(el => el == cat.id)
      if (idx > -1){
        this.categories.splice(idx, 1)
      } else if (this.categories.length < 4 ) {
        this.categories.push(cat.id)
      }
    },
    importMiddlewares(){
      if (this.importModal.text){
        this.middlewaresHash = this.importModal.text
      } else if (this.importModal.file){
        let reader = new FileReader()
        reader.readAsText(this.importModal.file)  
        
        reader.onload = e => {
          this.middlewaresHash = e.target.result;
        };
      }
      this.$refs['import-middlewares-modal'].hide()
    },
    publishTemplate(){
      this.loading = true
      let payload = {
        "name": this.name,
        "description": this.description,
        "middleware_hash": this.middlewaresHash,
        "categories": this.categories,
        "author_id": this.getLoggedUser.userId,
        "author_name": this.getLoggedUser.fullname,
      }

      this.$store.dispatch('template/createTemplate', payload)
        .then((resp)=>{
          successToast({text: "Template created"})
          this.loading = false
          this.$nextTick(()=>{
            this.$refs['manage-template-modal'].hide()
          })
        })
        .catch((err)=>{
          this.loading = false
          console.error(err)
          errorToast({text: "Couldn't create Template"})
        })
    },
    patchTemplate(){
      this.loading = true
      let payload = {
        "helper_id": this.helper_id,
        "name": this.name,
        "description": this.description,
        "middleware_hash": this.middlewaresHash,
        "categories": this.categories,
        "author_name": this.getLoggedUser.fullname,
        // "author_id": this.getLoggedUser.userId,
      }

      this.$store.dispatch('template/updateTemplate', payload)
        .then((resp)=>{
          this.loading = false
          successToast({text: "Template updated"})
          this.$refs['manage-template-modal'].hide()
        })
        .catch((err)=>{
          console.error(err)
          errorToast({text: "Couldn't update Template"})
        })
    },
    deleteTemplate(){
      this.loading = true
      this.$store.dispatch('template/deleteTemplate', {id: this.helper_id})   
        .then((resp)=>{
          this.loading = false
          successToast({text:"Template deleted"})
          this.$nextTick(()=>{
            this.$refs['delete-modal'].hide()
            this.$refs['manage-template-modal'].hide()
          })
          console.log('%c'+JSON.stringify(resp), 'color: limegreen');
        })
        .catch((err)=>{
          this.loading = false
          console.error(err)
        })
    }
  },
}
</script>


<style lang="scss" scoped>
@import '@/assets/scss/custom-utils.scss';


.disabled-low-opacity{
  opacity: 0.5;
  cursor: not-allowed;
}

.label-margin{
  margin: 0 5px 2px 5px;
}

.remove-middlewares-btn{
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.middlewares-container{
  position:relative;
  min-height: 200px;
  max-height: 300px;
  width: 100%;
  overflow: auto;
  border: 2px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  .middleware-hash-input{
    position: absolute;
    left: 50%;
    top:50%;
    transform: translate(-50%, -50%);

  }

  .middlewares-displayed{
    width: fit-content;
    margin: 20px auto 20px auto;
  }
}


.category-badge{
  margin: 3px 3px;
  padding: 3px 9px;
  color: white;
  font-size: 15px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  transition: all 0.15s;
  font-weight: bold;
  opacity: 0.3;
  &:active{
    border-color: rgba(255, 255, 255, 0.8);
  }
  &.viewing{
    opacity: 1;  
  }
}

</style>1