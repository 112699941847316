<template>
  <div class="position-relative">
    <div>
      <div class="d-flex justify-content-between align-items-end mb-50">
        <h4 class="mb-0">Middleware Templates</h4>
        <b-button class="text-success border-bottom-success square py-25 px-25 mx-1" size="sm" variant="none" @click="$emit('openAddTemplateModal')">
          <b-icon icon="plus-circle" class="mx-25"/>
          Add Template 
        </b-button>
      </div>

      <div class="small mb-25">
        <div>
          <b>Templates</b> are sections of Middlewares exported by other users.
        </div>
        <div>
          Anyone can create and publish a public Template
        </div>
      </div>
    </div>      
    
    <div class="sticky-helpers-header">
      <div class="mt-1 bg-dark-blue rounded p-50 border">
        <div class="d-flex justify-content-between align-items-start mb-25">
          <div class="font-weight-bold"> Categories Included</div>
          <b-button class="small py-0 px-25 mx-1" variant="none" @click="selectAllCategories()">
            <span class="small text-secondary font-weight-bold">
              Select all
            </span>
          </b-button>
        </div>
        
        <b-button 
          v-for="category in categories"
          :key="'categoty-picker-'+category.id" 
          class="category-badge" 
          :class="{'viewing': viewingCategories.includes(category.id)}"
          variant="none" 
          :style="`background-color: ${category.color};`"
          :title="category.description"
          @click="toggleCategory(category)"

        >
          <b-icon :icon="category.icon" scale="0.8"/>
          {{ category.label }}
        </b-button> 
      </div>

      <div class="mt-50">
        <div class="text-dark small mx-50 font-weight-bolder">
          Search by title or author
        </div>
        <b-input-group size="sm">
        
          <b-form-input type="search" placeholder="Search templates" v-model.trim="searchTerm" @input="searchInput()"/>
          <!-- <b-input-group-append> -->
            <template #append>
              <b-input-group-text>
                <b-icon icon="search"/>
              </b-input-group-text>
            </template>

          <!-- </b-input-group-append> -->
        </b-input-group>
      </div>
    </div>

    <div v-if="Array.isArray(categorizedTemplates)" class="pt-50">

      <template v-if="resultedTemplates.length > 0">
        <transition-group name="templates-list" tag="div">

          <b-button 
            variant="none" 
            v-for="template in resultedTemplates" 
            :key="'template-helper-'+template.id" 
            :ref="'template-helper-'+template.id" 
            class="template-container" 
            :class="{'is-open': openedTemplate == template.id}"
            @click="openTemplate(template)"
          >

            <b-button @click.stop="$emit('openEditTemplateModal',template)" variant="outline-secondary" class="edit-template-btn" v-if="String(template.author_id) == String(getLoggedUser.userId)">
              <b-icon icon="pencil-fill" scale="0.7"/>
            </b-button>
            <div class="font-weight-bolder text-dark">
              {{ template.name }}
            </div>
            <div class="template-description">
              {{ template.description }}
            </div>


            <b-collapse v-if="openedTemplate == template.id" appear visible>
              <div class="py-1">
                <div class="template-middleware-container">
                  <div align="center" class="my-1" v-if="!templateMiddlewares">
                    <b-spinner/>
                  </div>
                  <div v-else>
                    <div>
                      <flux-clipboard-middleware-display :middlewares="templateMiddlewaresParsed" class="middleware-display-container"/>
                      <b-button variant="favorite" class="import-template-btn" @click="$emit('forceImport', templateMiddlewares)">
                        IMPORT TEMPLATE
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
            
            <div class="d-flex justify-content-between align-items-end mt-25">
              <div class="d-flex flex-wrap">
                <div
                  v-for="c in template.categories" 
                  :key="`template-categorie-${template.id}-${c}`" 
                  class="minified-template-icon" 
                  :style="`background-color:${categoriesById[c].color};`"
                  :title="'Category - ' + categoriesById[c].label"
                  v-b-tooltip.hover.v-dark-blue
                >
                  <b-icon :icon="categoriesById[c].icon" scale="0.85" variant="light"/> 
                </div>
              </div>

              <div class="small" >
                By 
                <span class="author-name-btn" @click.stop="changeSearchTerm(template.author_name)">
                  {{ template.author_name }}
                </span>
              </div>
            </div>

          </b-button>
          
        </transition-group>
        <div v-if="resultedTemplates.length < filteredTemplates.length" align="center">
          <b-button variant="none" class="px-3" @click="limitLoadedTemplates = limitLoadedTemplates + loadByChuncksOf">
            <span class="text-success">
              Load more Templates
            </span>
          </b-button>
        </div>
      </template>
      
      <div v-else>
        <h4 class="my-3 text-secondary" align="center">
          No Templates matched
        </h4>
      </div>

    </div>

    <div v-else align="center" class="mt-3">
      <b-spinner/>
    </div>

</div>
</template>

<script>

import {
  BRow,
  BCol,
  BCard,
  BButton,
  BSpinner,
  BContainer,
  BSkeleton,
  BCollapse,
  BBadge,
  BOverlay,
  BFormInput,
  BTooltip,
  VBTooltip,
  VBToggle,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupText,
  BFormCheckbox,
} from "bootstrap-vue";
import TemplateCategories from "@/custom/class/Enum/Map/TemplateCategories.js"
import {mapGetters} from 'vuex'
import * as StringCompressor from '@/custom/class/FunctionClasses/StringCompressor.js'
import FluxClipboardMiddlewareDisplay from "@/views/pages/middleware/MiddlewareManagement/RearrangementOverlay/FluxClipboardMiddlewareDisplay.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BSpinner,
    BContainer,
    BSkeleton,
    BCollapse,
    BBadge,
    BOverlay,
    BFormInput,
    BTooltip,
    VBTooltip,
    VBToggle, 
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupText,
    BFormCheckbox,
    FluxClipboardMiddlewareDisplay,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      categories: new TemplateCategories().items,
      viewingCategories: [],
      searchTerm: '',
      openedTemplate: undefined,
      templateMiddlewares: undefined,

      limitLoadedTemplates: 0,
      loadByChuncksOf: 30,
    }
  },
  mounted () {
    this.init();
  },
  computed: {
    ...mapGetters(["getLoggedUser"]),
    ...mapGetters('template', ["getTemplates"]),
    categorizedTemplates(){
      let templates = this.getTemplates      
      if (!Array.isArray(templates)){
        return undefined
      }
      let cats = this.viewingCategories
      let r = []
      templates.forEach((t)=>{
        if (t.categories.some(e => cats.includes(e)) ){
          r.push(t)
        }
      })
      return r;
    },
    filteredTemplates(){      
      let templates = structuredClone(this.categorizedTemplates)
      let term = this.searchTerm.toLowerCase()
      let r = []

      if (term.startsWith('"') && term.endsWith('"')){  //Evoke Strong match
        let trimmedTerm = term.slice(1 , term.length-1)

        templates.forEach((t)=>{
          let n = new RegExp("\\b" + trimmedTerm + "\\b").test(t.name.toLowerCase())
          let a = new RegExp("\\b" + trimmedTerm + "\\b").test(t.author_name.toLowerCase())
          if ( a || n){
            r.push(t)
          }
        });
      } else { 
        templates.forEach((t)=>{
          if ( (t.length == 0 || t.name.toLowerCase().includes(term) || t.author_name.toLowerCase().includes(term) )){
            r.push(t)
          }
        });
      }


      return r
    },
    resultedTemplates(){
      let r = this.filteredTemplates
      if (Array.isArray(r)){
        return r.slice(0,this.limitLoadedTemplates)
      } 
      return [] 
    },
    categoriesById(){
      let cats = this.categories
      let r = {}
      cats.forEach((c)=>{
        r[c.id] = {
          icon: c.icon,
          color: c.color,
          label: c.label
        }
      })
      return r;
    },
    templateMiddlewaresParsed(){
      let m = this.templateMiddlewares
      if (m){
        return JSON.parse(StringCompressor.decompressFromBase64(m)) 
      }
      
    }

  },
  watch: {
    'getLoggedUser.userId'(newValue, oldValue) {
      if ( Number.isInteger(newValue) ){
        this.fetchTemplates()  
      }
    }
  },
  methods: {
    init() {
      this.selectAllCategories()
      this.fetchTemplates()
      this.limitLoadedTemplates = this.loadByChuncksOf
    },
    fetchTemplates(){
      if (!this.getLoggedUser || this.getLoggedUser.userId == null){
        return
      }
      this.resetOpened()
      
      this.$store.dispatch('template/fetchTemplates', {userId: this.getLoggedUser.userId})
    },
    fetchTemplateById(id){
      return this.$store.dispatch('template/fetchTemplateById', {id: id})
    },
    toggleCategory(cat){
      this.resetOpened()
      this.limitLoadedTemplates = this.loadByChuncksOf;
      let idx = this.viewingCategories.findIndex(el => el == cat.id)
      if (idx > -1){
        this.viewingCategories.splice(idx, 1)
      } else {
        this.viewingCategories.push(cat.id)
      }
    },
    async openTemplate(template){
      if (template.id == this.openedTemplate){
        return
      }
      this.templateMiddlewares = undefined;
      this.openedTemplate = template.id
      this.$nextTick(()=>{
        this.$refs['template-helper-'+template.id][0]?.scrollIntoView({block: "center", inline: "center", behavior: "smooth"});
      })
      let r = await this.fetchTemplateById(template.id)      
      
      if (this.openedTemplate == template.id){
        this.templateMiddlewares = r.middleware_hash
      }
    },
    searchInput(){
      this.resetOpened()
      this.limitLoadedTemplates = this.loadByChuncksOf;
    },
    changeSearchTerm(term){
      this.searchTerm = `"${term}"`
      this.searchInput()
    },
    resetOpened(){
      this.openedTemplate = undefined
      this.templateMiddlewares = undefined
    },
    sidebarToggled(isOpen){ // reffed down
      this.limitLoadedTemplates = this.loadByChuncksOf
      this.resetOpened()
    },
    selectAllCategories(){
      this.limitLoadedTemplates = this.loadByChuncksOf
      this.resetOpened()
      this.viewingCategories = this.categories.map(e => e.id)
    },
  },
}
</script>


<style lang="scss" scoped>
@import '@/assets/scss/custom-utils.scss';
.templates-list-enter-active,
.templates-list-leave-active {
  max-height: 300px;
  transition: all 0.25s ease-out;
}
.templates-list-enter-from,
.templates-list-leave-to {
  overflow: hidden;
  max-height: 0;
  opacity: 0;
}

.category-badge{
  margin: 3px 5px;
  padding: 3px 6px;
  color: white;
  font-size: 13px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  transition: all 0.15s;
  font-weight: bold;
  text-decoration: line-through;
  opacity: 0.3;
  &:active{
    border-color: rgba(255, 255, 255, 0.8);
  }
  &.viewing{
    opacity: 1;  
    text-decoration: none;
  }
}

.sticky-helpers-header{
  position: sticky;
  z-index: 1;
  padding-top: 10px ;
  top: -20px;
  background-color: #252d44;
  outline: 4px solid #252d44;
}

.import-template-btn{
  width: 100%;
  padding: 5px 5px;
  font-weight: bolder;
  border-radius: 0 0 5px 5px;
  font-size: 13px;
  border-top: 2px solid rgba(0, 0, 0, 0.4);
}


.template-container{
  position: relative;
  background-color: rgba(40, 0, 0, 0.1);
  background-color: #313952;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 8px;
  margin-bottom: 8px;  
  width: 100%;
  text-align: unset;
  line-height: unset;
  color: unset;
  outline: 1px solid transparent;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: all 0.2s;
  &:hover , &:focus{
    outline: 1px solid rgba(255, 255, 255, 0.2);
  }
  &:active{
    outline: 1px solid rgba(255, 255, 255, 0.8);
  }

  &.is-open{
    outline: 1px solid transparent;
    cursor: unset;
    user-select: auto;
  }


  .template-description{
    font-size: 12px;
    color: $secondary;
  }
  .author-name-btn{
    font-weight: bolder;
    cursor: pointer;
    &:hover{
      text-decoration: underline;
    }
  }
  .template-middleware-container{
    background-color: #222633;
    margin: 0 10px;
    border-radius: 5px;
    border: 1px solid #191c25;

    transition: all 0.3s;
    height: fit-content;


    .middleware-display-container{
      max-height: 300px;
      padding: 10px;
      overflow:auto;
    }
  }
}

.edit-template-btn{
  position: absolute;
  right: -2px;
  top: -2px;
  padding: 3px;
  border-radius: 5px;
  background-color: #313952;
  //  margin: 3px;
}

.minified-template-icon{
  margin-right: 5px;
  padding: 2px 3px;
  border-radius: 5px;
  outline: 1px solid rgba(0, 0, 0, 0.3);
}

</style>