<template>
  <div @mouseleave="onHover = false">
    <b-overlay :show="userCantEdit" bg-color="transparent" blur="0">
      <template #overlay><span/></template>
      <drop
        pragmaticOrigin="middleware"
        @drop="(data) => makeDrop(data)"
        @dragOver="onHover = true;"
        @dragLeave="onHover = false"
        @mouseleave="onHover = false"
      >
        <div class="svg-container" :style="`width: ${width}px; height: ${height}px;`">
          <div :style="`width: ${width}px;`" class="position-relative" v-if="clickAnimation">
            <div class="highlight-active"></div>
          </div>
          
          <svg v-if="direction === 'down'" viewBox="0 0 1000 1000" class="general h-100 w-100 cursor-pointer" :class="onHover ? 'hover' : ''" @click="emmitEvent(event)">
            <path
              class="arrow-general down-top-line"
              :style="`stroke-width: calc(6 * ${scale});`"
              d="
                M 500, 50 
                l 0, 250 
                m 0, -150
                l 0, 150 
              "
            />
            <path
              class="arrow-general down-bottom-line"
              :style="`stroke-width: calc(6 * ${scale});`"
              d="
                M 500, 300
                m 0, 0 
                m 0, 0 
                m 0, 0 
                l 0, 150
                M 500, 350
                l 0, 400 
              "
            />
            <text class="down-text text-uppercase" x="0" y="455" fill="#12e066" >
              {{ $t('middleware.add_middleware') }}
            </text>
            <path
              class="arrow-general down-cross cursor-pointer"
              :style="`stroke-width: calc(5 * ${scale}); opacity: 0;` "
              d="
                M 500, 350 
                l 0, 0 
                m 0, 0 
                l 0, 100
              "
            />
            <path
              class="arrow-general down-arrow"
              :style="`stroke-width: calc(6 * ${scale});`"
              d="
                M 400, 750
                l 200, 0 
                l -100, 100
                Z
              "
            />
            <!-- <rect  fill="transparent" x="-500" y="100" style="width: 100% !important; outline: 1px solid red;" height="590" class="cursor-pointer"/> -->
          </svg>
          <svg v-if="direction === 'right-down'" class="general h-100 w-100 cursor-pointer" viewBox="0 0 1000 1000" :class="onHover ? 'hover' : ''"  @click="emmitEvent(event)">
            <path
              class="arrow-general arrow-right-down-top"
              :style="`stroke-width: calc(6 * ${scale});`"
              d="
                M 25, 0
                l 0, 200
                q 0, 250
                  250, 250
                l 575, 0
              "
            />
            <text class="down-text" x="300" y="440" fill="#12e066">
              ADD MIDDLEWARE
            </text>
            
            <path
              class="arrow-general arrow-right-down-bottom"
              :style="`stroke-width: calc(6 * ${scale});`"
              d="
                M 850, 450
                l 600, 0
                q 250, 0
                  250, 250
                l 0, 100
              "
            />
            <path
              class="arrow-general right-down-cross cursor-pointer"
              :style="`stroke-width: calc(5 * ${scale});`"
              d="
                M 670, 450 
                l 220, 0 
                m 0, 0 
                l 220, 0
              "
            />
            <path
              class="arrow-general down-arrow"
              :style="`stroke-width: calc(6 * ${scale});`"
              d="
                M 1600, 800
                l 200, 0 
                l -100, 100
                Z
              "
            />
            <!-- <rect @click="emmitEvent(event)" fill="transparent" x="0" y="200" width="1700" height="450" class="cursor-pointer"/> -->
          </svg>
          <svg v-if="direction === 'left-down'" viewBox="0 0 1000 1000" class="general h-100 w-100 cursor-pointer" :class="onHover ? 'hover' : ''"  @click="emmitEvent(event)">
            <path
              class="arrow-general arrow-left-down-top"
              :style="`stroke-width: calc(6 * ${scale});`"
              d="
                M 1475, 0
                l 0, 200
                q 0, 250
                  -250, 250
                l -600, 0
              "
            />
            <text class="down-text" x="100" y="440" fill="#12e066">
              ADD MIDDLEWARE
            </text>
            <path
              class="arrow-general left-down-cross cursor-pointer"
              :style="`stroke-width: calc(5 * ${scale});`"
              d="
                M 400, 450 
                l 220, 0 
                m 0, 0 
                l 220, 0
              "
            />
            <path
              class="arrow-general arrow-left-down-bottom"
              :style="`stroke-width: calc(6 * ${scale});`"
              d="
                M 650, 450
                l -600, 0
                q -250, 0
                  -250, 250
                l 0, 100
              "
            />
            <path
              class="arrow-general down-arrow"
              :style="`stroke-width: calc(6 * ${scale});`"
              d="
                M -300, 800
                l 200, 0 
                l -100, 100
                Z
              "
            />
            <!-- <rect @click="emmitEvent(event)" fill="transparent" x="-200" y="200" width="1700" height="450" class="cursor-pointer"/> -->
          </svg>
        </div>
      </drop>
    </b-overlay>
  </div>
</template>

<script>
import { BCol, BOverlay } from "bootstrap-vue"
import Drop from '@/views/pages/middleware/MiddlewareManagement/Components/Drop.vue'
import { mapGetters } from "vuex";

export default {
  components: {
    BCol,
    Drop,
    BOverlay
  },
  props: {
    direction: {
      type: String,
      default: "down",
    },
    scale: {
      type: [ Number, String ],
      default: 1,
    },
    width: {
      type: [ Number, String ],
      required: true,
    },
    height: {
      type: [ Number, String ],
      required: true,
    },
    event: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      onHover: false,
      clickAnimation: false,
    }
  },
  computed: {
    ...mapGetters(["rearrangementOverlayOff"]),
    userCantEdit() {
      return !(this.$can('create') && this.rearrangementOverlayOff )
    }
  },
  methods: {
    makeDrop(data){
      this.$emit('dropAction', data)
      this.makeActiveAnimation()
      setTimeout(() => {
        this.onHover = false;
      }, 300);
    },
    
    emmitEvent(evt){
      if (this.$can('create')){
        this.$emit(evt)
        this.makeActiveAnimation()
      }
    },

    makeActiveAnimation(){
      this.clickAnimation = true
      setTimeout(() => {
        this.clickAnimation = false
      }, 1000);
    }
  },
  
};
</script>

<style lang="scss" scoped>

.svg-container {
  svg {
    line, 
    path { 
      &.arrow-general {
        transition: all 0.3s ease-in-out;
        stroke: #12e066;
        fill: none;
        vector-effect: non-scaling-stroke;
        stroke-linejoin: round;
      }
      &.down-arrow {
        fill: #12e066 !important
      }
    }
    
    &.general {
      opacity: 0.5
    }
    &.general:hover, &.general.hover {
      
      opacity: 1
    }
    .down-text {
      transition: all 0.2s ease-in-out;
      font-size: 130px; 
      font-weight: bold;
      opacity: 0;
    }
  }

  svg:hover, svg.hover {
    text.down-text{
      transition: all 0.4s ease-in-out;
      opacity: 1;
    }
  }

  svg:hover, svg.hover{
    path.down-top-line {
      d: path("M 500, 50 l 0, 200 m -100, 0 l 200, 0");
    }
  
    path.down-bottom-line {
      d: path("M 500, 275 m -50, 0 m 50, 300 m -100, 0 l 200, 0 m -100, 0 l 0, 200");
    }

    path.down-cross {
      opacity: 1 !important;
      d: path("M -100, 360 l 0, 100 m -50, -50 l 100, 0");
    }

    // right-down-arrow
    path.arrow-right-down-top {
      d: path("M 25, 0 l 0, 0 q 0, 250 250, 250 l 675, 0")
    }

    path.arrow-right-down-bottom {
      d: path("M 775, 550 l 675, 0 q 250, 0 250, 250 l 0, 50")
    }

    path.right-down-cross {
      d: path("M 200, 345 l 0, 100 m -50, -50 l 100, 0");
    }

    // left-down-arrow
    path.arrow-left-down-top {
      d: path("M 1475, 0 l 0, 0 q 0, 250 -250, 250 l -700, 0")
    }

    path.arrow-left-down-bottom {
      d: path("M 750, 550 l -700, 0 q -250, 0 -250, 250 l 0, 50")
    }

    path.left-down-cross {
      d: path("M 0, 345 l 0, 100 m -50, -50 l 100, 0");
    }
  }
}


@keyframes highlight-active{
  0%{
    width:0;
    height: 0;
    border: 1px solid transparent;
    outline: 1px solid var(--low-white);
    opacity: 1;
  }
  50%{
    width: 15px;
    height: 15px;
    border: 5px solid var(--flux-bg-color);
    outline: 2px solid var(--low-white);
    opacity: 1;
  }

  75%{
    width: 10px;
    height: 10px;
    border: 10px solid var(--flux-bg-color);
    outline: 2px solid var(--low-white);
    opacity: 0.7;
  }
  100%{
    width: 5px;
    height: 5px;
    border: 10px solid var(--flux-bg-color);
    outline: 1px solid var(--low-white);
    opacity: 0.01;
  }
}

.highlight-active{
  --low-white: rgba(31, 208, 107, 0.3);
  --flux-bg-color: #0f1422;
  box-sizing: content-box;
  width: 1px;
  height: 1px;
  border-radius: 200px !important;
  position: absolute;
  background-color: var(--success);
  position: absolute;
  left: 50%;
  top: 30px;
  transform: translate(-50%, -50%);
  pointer-events: none;

  animation-name: highlight-active;
  animation-duration: 0.4s;
  animation-iteration-count: 1;

}
</style>