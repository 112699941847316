<template>
  <b-row class="pt-0 default-bg">
    <b-col>
      <slot name="header">
        
      </slot>
      <b-collapse :visible="headerExpanded">
        <div>
          <div class="d-flex justify-content-between">
            <div>
              <span class="font-weight-bolder text-muted mr-1">Showing variables category:</span>
              <b-badge
                v-for="item in categories.items"
                @click="toggleActiveCategory(item.script_tag)"
                :variant="getCategoryVariant(item.script_tag)"
                class="mr-50 cursor-pointer"
                :class="category_active[item.script_tag] ? '' : 'opacity-50'"
                :key="`var-category-${item.id}`"
                >{{ $t(item.label) }}</b-badge
              >
            </div>
            <div>
              <div v-if="partiallyLoaded" class="">
                <span class="small text-secondary">Loading...</span>
                <b-spinner small variant="secondary" class="mx-75"/>
              </div>
            </div>
          </div>

          <draggable-items
            v-if="vars"
            :id="getID('variables')"
            :items="draggableVars"
            :variants="variant_map"
          />
          <div v-else class="mt-1" variant="secondary">
            <b-skeleton height="28px" />
            <b-skeleton height="80px" />
          </div>
        </div
      >
    </b-collapse>
    </b-col>
    
    <div class="w-100">
      <b-button class="w-100 py-50 toggle-header-bar" @click="toggleHeader()">
        <span class="mx-2 w-25 text-secondary" v-if="headerExpanded"><small>{{ $t(`common.terms.hide_vars`) }}</small></span>
        <span class="mx-2 w-25 text-secondary" v-else><small>{{ $t(`common.terms.show_vars`) }}</small></span>
      </b-button>
    </div>

  </b-row>
</template>

<script>
import EnumVariableCategories from "@/custom/class/Enum/VariableCategories";
import { BBadge,
  BRow,
  BCol, 
  BSkeleton, 
  BSkeletonTable, 
  BButton,
  BCollapse,
  BSpinner
  } 
from "bootstrap-vue";
import { v4 as uuidv4 } from "uuid";
import DraggableItems from "./DraggableItems.vue";
import { mapGetters } from 'vuex';
import { makeToast } from "@/layouts/components/Popups";


export default {
  components: {
    BBadge,
    DraggableItems,
    BRow,
    BCol,
    BSkeleton,
    BSkeletonTable,
    BButton,
    BCollapse,
    BSpinner
  },
  props: {
    middleware_id: {
      type: Number,
      required: true,
    },
    headerExpandedAtInit:{
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      categories: new EnumVariableCategories(),
      variant_map: {},
      uuidMap: {},
      category_active: { env: true, realtime: true, system: false },
      headerExpanded: true,
      partiallyLoaded: false,
    };
  },
  computed: {
    ...mapGetters(["getVariablesPannelVars"]),
    vars(){
      return this.getVariablesPannelVars;
    },
    transmissionID() {
      return this.$route.params.transmissionID;
    },
    draggableVars() {
      let out = {};
      this.category_active;

      if (this.vars === undefined) {
        return out;
      }

      Object.keys(this.vars).forEach((key) => {
        if (this.category_active[key]) {
          out = Object.assign(
            out,
            this.vars[key].reduce((previous, current) => {
              previous[current.path] = current.value;
              this.variant_map[current.path] = this.getVariant(key);

              return previous;
            }, {})
          );
        }
      });

      return out;
    },
    allVars() {
      let out = {};

      if (this.vars === undefined) {
        return out;
      }

      Object.keys(this.vars).forEach((key) => {
        out = Object.assign(
          out,{ [this.getVariant(key)] :
            this.vars[key].reduce((previous, current) => {
              previous[current.path] = current.value;
              // previous["variant"] = this.getVariant(key);
              this.variant_map[current.path] = this.getVariant(key);

              return previous;
          }, {})
        });
      });

      return out;
    },
  },
  mounted() {
    this.headerExpanded = this.headerExpandedAtInit
    // this.getVariables();
    if (this.vars){
      this.$emit("loaded");
      this.partiallyLoaded = true;
    }
  },
  methods: {
    getVariables() {

      let env = JSON.parse(
        localStorage.getItem(`currentEnv-${this.transmissionID}`)
      );

      if (!env){
        makeToast({
          title: 'Error',
          text: 'Please create an Environment',
          variant: 'danger',
          icon: "XIcon"
        })
      }

      const payload = {
        transmission_id: this.transmissionID,
        categories: [
          {
            id: 1,
            data: {
              environment_id: env.id,
            },
          },
          {
            id: 2,
            data: {
              middleware_id: this.middleware_id,
            },
          },
          {
            id: 3,
            data: null,
          },
        ],
      };

      this.$store
        .dispatch("getPanelVars", payload)
        .then((response) => {
          // this.vars = response;
          this.partiallyLoaded = false
          this.$emit("loaded");
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getID(key) {
      if (this.uuidMap[key]) {
        return this.uuidMap[key];
      }

      const uuid = uuidv4();
      this.uuidMap[key] = uuid;

      return uuid;
    },
    getVariant(key) {
      return {
        env: "light-primary",
        realtime: "light-warning",
        system: "light-info",
      }[key];
    },
    getCategoryVariant(key) {
      if (!this.category_active[key]) {
        return "secondary";
      }

      return {
        env: "light-primary",
        realtime: "light-warning",
        system: "light-info",
      }[key];
    },
    toggleActiveCategory(key) {
      let tmp = this.category_active;
      tmp[key] = !tmp[key];
      this.category_active = tmp;
    },
    getVariantMap() {
      return this.variant_map;
    },
    getAllVars() {
      return this.allVars;
    },
    toggleHeader(){
      this.headerExpanded = !this.headerExpanded
    }
  },
};
</script>

<style lang="scss" scoped>


.toggle-header-bar,.toggle-header-bar:focus, .toggle-header-bar:active {
	background-color: #151925 !important;
	box-shadow:  none;
	border: none;
	border-radius: 0;
}
.toggle-header-bar:hover{
  box-shadow: 0 0 10px black !important;
}


.opacity-50 {
  opacity: 0.5;
}

.default-bg {
  background-color: #151925;
}
</style>